import { Component } from '@angular/core';
import { PluginComponent } from '../../plugin.component';
import { PluginPanelService } from '../../plugin-panel.service';
import { DocumentService } from 'app/core/service/document.service';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_TOGGLEPANEL } from 'app/pubsub.topics';
import { PLUGIN_PANEL_COMPONENT_KEYS } from '../../plugin-panel-component-keys';
import { Authority } from 'app/shared/enum/authority.enum';

@Component({
  selector: 'jhi-configuration-export-esef',
  templateUrl: './configuration-export-esef.component.html',
  styleUrls: ['./configuration-export-esef.component.scss'],
})
export class ConfigurationExportEsefComponent extends PluginComponent {
  public readonly Authority = Authority;
  public validate = true;

  constructor(pluginPanelService: PluginPanelService, private documentService: DocumentService) {
    super(pluginPanelService);
  }

  goBack(): void {
    pubsub.fire(EDITOR_PLUGIN_TOGGLEPANEL, {
      component: PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT,
      title: 'htmlEditor.plugins.documentExport.title',
    });
  }

  exportDocumentESEF(): void {
    this.documentService.downloadRegulatorArchive(this.validate);
    this.closePanel();
  }
}
