// AREVIO Service
export const AREVIO_ASKFACT = 'arevio-service/ask-fact';
export const AREVIO_GOTFACT = 'arevio-service/got-fact';
export const AREVIO_ASKRCSFDATA = 'arevio-service/ask-rcsf-data';
export const AREVIO_GOTRCSFDATA = 'arevio-service/got-rcsf-data';

// Comments
export const EDITOR_COMMENTS_CLOSE_COMMENTS = 'editor-comments/close-panel';
export const EDITOR_COMMENTS_SELECTION_CHANGED = 'editor-comments/selection-changed';
export const EDITOR_COMMENTS_NOTIFY = 'editor-comments/notify-user';
export const EDITOR_COMMENTS_USER_LIST = 'editor-comments/user-list';
export const EDITOR_COMMENTS_FILTER_SESSION_FORM_RETRIVE = 'editor-comments/filter-session-form';
export const EDITOR_COMMENTS_FILTER_SESSION_EDITOR_RETRIVE = 'editor-comments/filter-session-editor';
export const EDITOR_COMMENTS_FOUND_RESULTS = 'editor-comments/found-results';
export const EDITOR_COMMENTS_FROM_URL_PARAMS = 'editor-comments/open-from-url-params';
export const EDITOR_COMMENTS_OPEN_COMMENTS = 'editor-comments/open-comments';

// Find-Replace
export const EDITOR_FINDREPLACE_FIND = 'editor-findreplace/find';
export const EDITOR_FINDREPLACE_NEXT = 'editor-findreplace/next';
export const EDITOR_FINDREPLACE_PREV = 'editor-findreplace/prev';
export const EDITOR_FINDREPLACE_REPLACE = 'editor-findreplace/replace';
export const EDITOR_FINDREPLACE_REPLACE_ALL = 'editor-findreplace/replace-all';
export const EDITOR_FINDREPLACE_RESET = 'editor-findreplace/reset';
export const EDITOR_FINDREPLACE_RESPONSE = 'editor-findreplace/response';

// Footnote
export const EDITOR_FOOTNOTE_TOGGLE = 'editor-footnote/toggle';
export const EDITOR_FOOTNOTE_INSERT = 'editor-footnote/insert-footnote';
export const EDITOR_FOOTNOTE_REMOVE = 'editor-footnote/remove-footnote';
export const EDITOR_FOOTNOTE_REFRESH_DISPLAY = 'editor-footnote/refresh-display';
export const EDITOR_FOOTNOTE_UPDATE = 'editor-footnote/update';

// CKEditor and right panel (plugins) communications
export const EDITOR_PLUGIN_APPLYSTYLE = 'editor-plugin/apply-style';
export const EDITOR_PLUGIN_COLLAPSEPANEL = 'editor-plugin/collapse-panel';
export const EDITOR_PLUGIN_OPENPANEL = 'editor-plugin/open-panel';
export const EDITOR_PLUGIN_COLLAPSEDPANEL = 'editor-plugin/collapsed-panel';
export const EDITOR_PLUGIN_OPENEDPANEL = 'editor-plugin/opened-panel';
export const EDITOR_PLUGIN_CANTOPENPANEL = 'editor-plugin/cant-open-panel';
export const EDITOR_PLUGIN_EXECUTE = 'editor-plugin/execute-command';
export const EDITOR_PLUGIN_INSERTIMAGE = 'editor-plugin/insert-image';
export const EDITOR_PLUGIN_INSERTSPECIALCHARACTER = 'editor-plugin/insert-special-character';
export const EDITOR_PLUGIN_SETSIDEBAR = 'editor-plugin/set-sidebar-container';
export const EDITOR_PLUGIN_TOGGLEPANEL = 'editor-plugin/toggle-panel';
export const EDITOR_PLUGIN_GOTODYNAMICDATA = 'editor-plugin/viewer-goto-dynamic-data';
export const EDITOR_PLUGIN_VIEWDYNAMICDATA = 'editor-plugin/viewer-select-dynamic-data';
export const EDITOR_PLUGIN_VIEWER_STATE = 'editor-plugin/viewer-state';
export const EDITOR_PLUGIN_HIDE_SEPARATORS_STATE = 'editor-plugin/hide-separators-state';
export const EDITOR_PLUGIN_SHOW_ANNEXES_STATE = 'editor-plugin/show-annexes-state';
export const EDITOR_PLUGIN_VIEWFACT = 'editor-plugin/viewer-select-fact';
export const EDITOR_PLUGIN_INSERT_GRAPH = 'editor-plugin/insert-graph';
export const EDITOR_PLUGIN_DISABLE_GRAPH = 'editor-plugin/disable-graph';
export const EDITOR_PLUGIN_CLICK_IMPORT_BTN = 'editor-plugin/click-import-btn';
export const EDITOR_PLUGIN_ONGOING_IMPORT = 'editor-plugin/ongoing-import';
export const EDITOR_PLUGIN_HIDE_TOOLBAR = 'editor-plugin/hide-editor-toolbar';
export const EDITOR_PLUGIN_SELECTION_TEXT_BLOCK = 'editor-plugin/selection-text-block';
export const EDITOR_PLUGIN_TEXT_BLOCK_ELEMENT = 'editor-plugin/html-element-text-block';
export const EDITOR_PLUGIN_DISPLAY_REFRESH = 'editor-plugin/display-refresh';
export const EDITOR_PLUGIN_DISPLAY_IMAGES_REFRESH = 'editor-plugin/display-images-refresh';
export const EDITOR_PLUGIN_CLICK_SEPARATOR = 'editor-plugin/separator-click';
export const EDITOR_PLUGIN_COPY_PASTE_STATE = 'editor-plugin/copy-paste-state';
export const EDITOR_PLUGIN_COPY_STATE = 'editor-plugin/copy-state';
export const EDITOR_PLUGIN_HIGHLIGHT_GRAPH_STATE = 'editor-plugin/highlight-graphs-state';
export const EDITOR_PLUGIN_HIGHLIGHT_TRACK_CHANGES_STATE = 'editor-plugin/highlight-track-changes-state';

// Image placeholder
export const IMAGE_PLACEHOLDER_SERVICE_UPDATE_PANEL = 'image-placeholder-service/update-placeholder-panel';
export const IMAGE_PLACEHOLDER_SERVICE_CLOSE_PANEL = 'image-placeholder-service/close-placeholder-panel';
export const EDITOR_IMAGE_PLACEHOLDER_UPDATE = 'editor-image-placeholder/update';
export const EDITOR_IMAGE_PLACEHOLDER_DELETE = 'editor-image-placeholder/delete';
export const EDITOR_IMAGE_PLACEHOLDER_CHECK_REMOVED = 'editor-image-placeholder/checked-removed';

// Summary plugin
export const EDITOR_SUMMARY_INSERT = 'editor-summary/insert';
export const EDITOR_SUMMARY_MODIFY = 'editor-summary/modify';
export const EDITOR_SUMMARY_REFRESH = 'editor-summary/refresh';
export const EDITOR_SUMMARY_SELECT = 'editor-summary/select';
export const EDITOR_SUMMARY_COPY = 'editor-summary/copy';
export const EDITOR_SUMMARY_PASTE = 'editor-summary/paste';

// Localize numbers
export const EDITOR_LOCALIZE_NUMBERS_REPLACE = 'editor-localize-numbers/replace';

// Format Service
export const FORMAT_ASKFORMATEDDATA = 'format-service/ask-formated-data';
export const FORMAT_ASKRCSFFORMATEDDATA = 'format-service/ask-rcsf-formated-data';
export const FORMAT_GOTFORMATEDDATA = 'format-service/got-formated-data';
export const FORMAT_GOTRCSFFORMATEDDATA = 'format-service/got-rcsf-formated-data';

// STYLE Service
export const STYLE_SERVICE_ASKSTYLE = 'style-service/ask-styles';
export const STYLE_SERVICE_GOTSTYLE = 'style-service/got-styles';
export const STYLE_SERVICE_READY = 'style-service/ready-styles';
export const APPLIED_STYLE = 'style-service/applied-style';
export const ASK_APPLIED_STYLE = 'style-service/ask-applied-style';

// Summary Service
export const EDITOR_ASK_SUMMARY_WITH_TITLES = 'summary-service/ask-summary-with-titles';
export const EDITOR_GOT_SUMMARY_WITH_TITLES = 'summary-service/got-summary-with-titles';

// Summary Item Service
export const EDITOR_UPDATE_TITLE_IN_SUMMARY = 'summary-item-service/update-title';
export const EDITOR_TITLE_UPDATED_IN_SUMMARY = 'summary-item-service/title-updated';

// Preview
export const PREVIEW_INIT = 'preview/init';
export const PREVIEW_SELECT_NODE_IN_PREVIEW = 'preview/select-node/preview';
export const PREVIEW_SELECT_NODE_IN_EDITOR = 'preview/select-node/editor';
export const PREVIEW_LOADED = 'preview/loaded';

// Context
export const CONTEXT_WSC_INIT_INSTANCE = 'context/wsc-init-instance';
export const CONTEXT_EDITOR_CHANGE_INSTANCE = 'context/editor-change-instance';

// zoom
export const EDITOR_PLUGIN_ZOOM_INIT = 'editor-plugin/zoom-init';
export const EDITOR_PLUGIN_ZOOM_SESSION_CHANGE = 'editor-plugin/zoom-session-changed';
export const EDITOR_PLUGIN_ZOOM_UPDATE = 'editor-plugin/zoom-update';

// LocalStorage
export const LS_SET_STATE = 'local-storage/set';
export const LS_GET_STATE = 'local-storage/get';

// SessionStorage
export const SESSION_STORAGE_SET_STATE = 'session-storage/set';
export const SESSION_STORAGE_GET_STATE = 'session-storage/get';

// WSC
export const WSC_STATE = 'wsc/state';

// Table edition
export const EDITOR_PLUGIN_VIEWTABLE = 'table/view-table';
export const EDITOR_PLUGIN_APPLY_TABLE_MODEL = 'table/apply-table-model';
export const EDITOR_PLUGIN_APPLY_COLUMNS_WIDTH = 'table/apply-columns-width';
export const EDITOR_PLUGIN_RESTORE_COLUMNS_WIDTH = 'table/restore-columns-width';
export const EDITOR_PLUGIN_APPLY_ROW_LABEL = 'table/apply-row-label';
export const EDITOR_PLUGIN_APPLY_COLUMN_LABEL = 'table/apply-column-label';
export const EDITOR_PLUGIN_APPLY_ROW_HEADER = 'table/apply-row-header';
export const EDITOR_PLUGIN_VIEW_COLUMN = 'table/view-column';
export const EDITOR_PLUGIN_VIEW_ROW = 'table/view-row';
export const EDITOR_PLUGIN_DISABLE_PYJAMA_ROW = 'table/disable-pyjama-row';
export const EDITOR_PLUGIN_DISABLE_PYJAMA_COLUMN = 'table/disable-pyjama-column';
export const EDITOR_PLUGIN_REVERSE_PYJAMA_ROW = 'table/reverse-pyjama-row';
export const EDITOR_PLUGIN_UPDATE_HEIGHT_ROW = 'table/update-height-row';

// Table Accessibility
export const EDITOR_GO_TO_TABLE = 'table/go-to-table';
export const EDITOR_SET_CAPTION = 'table/set-caption';
export const EDITOR_PLUGIN_APPLY_COLUMN_HEADER = 'table/apply-column-header';

// TOC
export const TOC_UPDATE_FROM_TITLE = 'table-of-content/title-updated';
export const TOC_UPDATED = 'table-of-content/updated';

// TOGGLE WARNING ZONES
export const TOGGLE_WARNING_ZONES_APPLY = 'warning-zones/toggle-hide';
export const TOGGLE_WARNING_ZONES_STATE = 'warning-zones/state';

// TOGGLE XBRL ERRORS
export const EDITOR_XBLR_ERRORS_TOGGLE = 'editor-xbrl-errors/toggle';

// XBRL operations
export const GROUP_OF_FACTS_INSERTED_IN_EDITOR = 'xbrl/facts-inserted';
export const FACT_INSERTED_IN_EDITOR = 'xbrl/fact-inserted';
export const FACT_DELETED_FROM_EDITOR = 'xbrl/fact-deleted';
export const FOOTNOTE_INSERTED_IN_EDITOR = 'xbrl/footnote-inserted';
export const FOOTNOTE_DELETED_FROM_EDITOR = 'xbrl/footnote-deleted';
export const FOOTNOTE_HIGHLIGHT_IN_EDITOR = 'xbrl/footnote-highlight';
export const FOOTNOTE_LIST_IN_EDITOR = 'xbrl/footnote-list';
export const FOOTNOTE_LIST_IN_EDITOR_REQUEST = 'xbrl/footnote-list-request';
export const FOOTNOTE_SCROLL_TO = 'xbrl/scrooll-to-footnote';
export const INSERT_TEXT_BLOCK = 'xbrl/insert-text-block';
export const COPY_TEXT_BLOCK = 'xbrl/copy-text-block';
export const PASTED_TEXT_BLOCK = 'xbrl/pasted-text-block';
export const DELETE_TEXT_BLOCK = 'xbrl/delete-text-block';
export const REFRESH_TEXT_BLOCKS = 'xbrl/refresh-text-blocks';
export const GET_COPIED_FORMAT = 'xbrl/get-copied-format';

// Lookup plugin
export const EDITOR_LOOKUP_INSERT = 'editor-lookup/insert';
export const EDITOR_LOOKUP_MODIFY = 'editor-lookup/modify';
export const EDITOR_PLUGIN_SELECTION_LOOKUP = 'editor-lookup/select';

// Highly Graphical Pages
export const INSERT_BREAK_PAGES = 'graphical-pages/insert-break-pages';
export const GRAPHICAL_PAGES_INSERTED = 'graphical-pages/inserted';
export const ASK_FOR_SELECTED_UD = 'documentary-service/ask-selected-UD';
export const SELECTED_UD = 'documentary-service/selected-UD';

// Dialog
export const OPEN_DIALOG = 'dialog-service/open-dialog';

// Websockets
export const WEBSOCKET_CONNECTED = 'websocket_connected';

// Copy-paste style
export const EDITOR_PLUGIN_GET_PASTE_VALUE = 'editor/copy-style-value';

// Highchart
export const EDITOR_PLUGIN_VIEWHIGHCHART = 'highchart/view-highchart';

// Split DU
export const EDITOR_SET_AUTHORITIES = 'editor/set-authorities';
export const SPLIT_DU_EXECUTION = 'split-du/execution';
export const SPLIT_DU_REMOVE_CONTENT = 'split-du/remove-content';
export const NAVIGATE_TO_EDITOR = 'editor/navigate';
export const SPLIT_DU_CLEANED = 'split-du/cleaned';
