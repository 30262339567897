import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PluginComponent } from '../plugin.component';
import { PluginPanelService } from '../plugin-panel.service';
import * as FrenchContent from 'app/../i18n/fr/highchart_fr.json';
import * as EnglishContent from 'app/../i18n/en/highchart_en.json';
import { HighChartService } from 'app/core/service/highchart.service';
import { IHighchartTemplate } from 'app/shared/model/highchart-template.model';
import { StyleService } from 'app/core/service/style.service';
import { IFontFamily } from 'app/shared/model/font.model';
import { IColor } from 'app/shared/model/color.model';
declare const highed: any;

import { TranslateService } from '@ngx-translate/core';

import pubsub from 'app/pubsub';
import { EDITOR_PLUGIN_INSERT_GRAPH } from 'app/pubsub.topics';
import { forkJoin } from 'rxjs';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';
import { sanitizeHTMLRecurse } from 'app/html-editor/plugins/highchart/DOMSanitize.util';

@Component({
  selector: 'jhi-plugin-highchart',
  template: '',
  styleUrls: ['./highchart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HighChartPluginComponent extends PluginComponent implements OnInit {
  public templateList: IHighchartTemplate[] = [];
  highchartModel: any;
  selectedTemplateId: number | null;
  selectedTemplateName: string;
  dropdownBody: any;
  translated = false;

  chartCat = 'option.cat.chart';
  axesCat = 'option.cat.axes';
  legendCat = 'option.cat.legend';

  private defaultColor: string;
  private defaultFF: string;

  constructor(
    pluginPanelService: PluginPanelService,
    private highchartService: HighChartService,
    private styleService: StyleService,
    private languageService: JhiLanguageService,
    private translate: TranslateService
  ) {
    super(pluginPanelService);
  }

  public ngOnInit(): void {
    forkJoin([
      this.highchartService.getTemplatesByProjectId(),
      this.styleService.getFontFamilyList(),
      this.styleService.getColors(),
    ]).subscribe(([templates, ffamilies, colors]: [IHighchartTemplate[], IFontFamily[], IColor[]]) => {
      this.templateList = templates;
      highed.meta.fonts = [];
      highed.meta.colors = [];
      ffamilies.forEach((projectFontFamily: IFontFamily) => {
        // set available fontfamilies
        highed.meta.fonts.push(projectFontFamily.name);

        if (projectFontFamily.defaultff) {
          this.defaultFF = projectFontFamily.name ?? '';
        }
      });
      colors.forEach((color: IColor) => {
        highed.meta.colors.push(color.hexCode?.toLowerCase());

        if (color.defaultColor) {
          this.defaultColor = color.hexCode?.toLowerCase() ?? '';
        }
      });
      // Add white colors because the highchart pick add the last color on picker background.
      highed.meta.colors.push('#ffffff');
      this.openHighchart();
    });
  }

  private cleanDom(): void {
    const modals = Array.from(document.getElementsByClassName('highed-overlay-modal'));
    for (const modal of modals) {
      modal.remove();
    }

    const containers = Array.from(document.getElementsByClassName('highed-ctx-container'));
    for (const container of containers) {
      container.remove();
    }

    const dimmers = Array.from(document.getElementsByClassName('highed-dimmer'));
    for (const dimmer of dimmers) {
      dimmer.remove();
    }
  }

  openHighchart(): void {
    let templateIdSelected: number | null = this.templateList[0].id;
    const modelElement = this.highchartService._tmpHighchart;
    let configJson: any = {};
    if (modelElement?.template) {
      configJson = modelElement.configJson;
      templateIdSelected = parseInt(modelElement.template, 10);
      this.updateTemplateConfigFromModel(templateIdSelected, configJson);
    } else {
      configJson = JSON.parse(this.templateList[0].config);
      const csv = configJson?.settings?.dataProvider?.csv;
      if (csv) {
        const csvSplit = csv.split('\n');
        configJson.settings.dataProvider.csv = csvSplit[0];
      }

      if (configJson.options?.data) {
        configJson.options.data.csv = null;
      }
    }

    const highedOptions = sanitizeHTMLRecurse(highed.meta.optionsExtended.options);
    highedOptions['option.cat.chart'][2].options[10].defaults = `[ "${configJson.options?.colors.join('" , "')}" ]`;
    highed.ready(() => {
      // clean dom to prevent multiple modals
      this.cleanDom();

      // Apply lang
      this.setHighChartEditorLanguage(this.languageService.getCurrentLanguage());

      // Define the highchar model
      this.highchartModel = highed.ModalEditor(
        false,
        {
          allowDone: true,
          availableSettings: [
            'template',
            'title--text',
            'subtitle--text',
            'colors',
            'yAxis-title--style',
            'yAxis--type',
            'yAxis--opposite',
            'yAxis--reversed',
            'yAxis-labels--format',
          ],
          type: 'simple',
          hideSettingsButton: true,
          importer: {
            toolbar: {
              disableImport: true,
              disableExport: true,
            },
          },
          // defaultChartOptions: configJson.options,
        },
        (chart: any) => {
          // export json data, svg and child elements from highchart editor
          const config = chart.toProject();
          config.templateName = this.selectedTemplateName;
          pubsub.fire(
            EDITOR_PLUGIN_INSERT_GRAPH,
            {
              svg: chart.export.svg(),
              configJson: config,
              templateId: this.selectedTemplateId,
            },
            this.getEditorTopicContext()
          );

          this.cleanDom();
        }
      );

      this.highchartModel.editor.chart.loadProject(configJson);

      if (templateIdSelected) {
        this.setTemplateDropdown(templateIdSelected);
      }

      this.addCancelButton();

      this.highchartModel.show();
      // Mantis#0182991: fix randow "empty" highchart editor modal
      // Has to add a setTimeout... see https://github.com/highcharts/highcharts-editor/blob/master/src/core-ui/highed.overlaymodal.js#L125
      // Highchart do already setTimeout but 300ms seems to not be enough...
      // Use 700 to ensure the resize is done after the modal is displayed
      setTimeout(() => {
        this.highchartModel.editor.resize();
      }, 700);
    });
  }

  private updateTemplateConfigFromModel(templateIdSelected: number, configJson: any): void {
    let templateFound = this.templateList.find((template: any) => template.id === templateIdSelected);
    if (!templateFound && configJson.templateName) {
      // Check by name
      templateFound = this.templateList.find((template: any) => template.name === configJson.templateName);
    }

    if (templateFound) {
      const templateJson = JSON.parse(templateFound.config);
      templateJson.options.colors = configJson.options.colors;
      // Keep title style from template
      configJson.options.title.style = templateJson.options.title.style;

      templateJson.options.title = configJson.options.title;
      configJson.options.subtitle.style = templateJson.options.subtitle.style;
      templateJson.options.subtitle = configJson.options.subtitle;
      // Keep label color
      if (templateJson.options.yAxis[0] && configJson.options.yAxis[0].labels?.style) {
        configJson.options.yAxis[0].labels.style.color = templateJson.options.yAxis[0]?.labels.style?.color;
      }
      templateJson.options.yAxis = configJson.options.yAxis;
      templateFound.config = JSON.stringify(templateJson);
    }
  }

  // set the highchart editor lang based on the current lang
  private setHighChartEditorLanguage(currentLang: string): void {
    const langContent = this.getLanguageContent(currentLang);
    highed.installLanguage({ language: currentLang, entries: langContent });
    highed.setLang(currentLang);
  }

  // get Language content based on the current lang
  private getLanguageContent(currentLang: string): any {
    let langContent: any;
    if (!this.translated) {
      langContent = currentLang === 'fr' ? (FrenchContent as any).default : (EnglishContent as any).default;

      const labels = Array.from(document.getElementsByClassName('highed-customize-field-label'));

      for (const label of labels) {
        const htmlLabel = label as HTMLElement;
        htmlLabel.innerText = this.translate.instant(`option.text.${htmlLabel.innerText.replace(/ /gi, '.')}`);
      }

      const doneButton = document.getElementsByClassName('highed-done-button');
      if (doneButton.length > 0) {
        (doneButton[0] as HTMLElement).innerText = this.translate.instant(`doneCaption`);
        this.translated = true;
      }

      const dropArea = document.getElementsByClassName('highed-dtable-drop-zone');
      if (dropArea.length > 0) {
        (dropArea[0] as HTMLElement).innerHTML = this.translate.instant(`dropZone.text`);
      }
    }
    return langContent;
  }

  private addCancelButton(): void {
    // take the done button parrent and inject a new button in that node
    const doneButton = document.getElementsByClassName('highed-done-button')[0];
    const createButton = highed.dom.get(doneButton);
    const parentElement = createButton.parentElement;
    const cancelButton = highed.dom.cr('button', 'highed-done-button', this.translate.instant('cancelBtn'), 'cancel-button');

    cancelButton.addEventListener('click', () => {
      this.highchartModel.hide();
    });

    highed.dom.ap(parentElement, cancelButton);
  }

  private setTemplateDropdown(templateIdSelected: number | null): void {
    // Create dropdown for template selection
    const domTemplate = highed.dom.get('template');
    const parentElement = domTemplate.parentElement;
    const select = highed.dom.cr('div', 'highed-dropdown', '');
    this.dropdownBody = highed.DropDown();

    const reducedList = this.templateList.reduce(function (a: any, b: IHighchartTemplate): any {
      a.push({ title: b.name, id: b.id });
      return a;
    }, []);
    this.dropdownBody.addItems(reducedList);

    this.dropdownBody.on('Change', (selected: any) => {
      // retrieved template by id
      templateIdSelected = this.onTemplateChange(selected, reducedList, templateIdSelected);
    });

    // select the first one by default
    this.dropdownBody.selectById(templateIdSelected ?? reducedList[0].id);

    // Clean parent node
    parentElement.removeChild(domTemplate);

    // Add dropdown
    highed.dom.ap(parentElement, select);
    highed.dom.ap(select, this.dropdownBody.container);
  }

  private onTemplateChange(selected: any, reducedList: any, templateIdSelected: number | null): number | null {
    let template = this.templateList.find((temp: IHighchartTemplate) => temp.id === selected.id());

    if (!template) {
      template = this.templateList.find((temp: IHighchartTemplate) => temp.id === reducedList[0].id);
    }

    if (template) {
      this.selectedTemplateId = template.id ?? null;
      this.selectedTemplateName = template.name ?? '';

      const config = JSON.parse(template.config);
      this.setValues(config);
      this.setHighChartEditorLanguage(this.languageService.getCurrentLanguage());
      if (!templateIdSelected) {
        this.forceReloadTable(config);
      }
    }
    templateIdSelected = null;

    return templateIdSelected;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setValues(config: any): void {
    // Set title value
    const title = highed.dom.get('title--text');
    title.value = config.options.title?.text ?? '';

    // Set subtitle value
    const subtitle = highed.dom.get('subtitle--text');
    subtitle.value = config.options.subtitle?.text ?? '';

    const events = this.highchartModel.editor.events;
    const highedOptions = sanitizeHTMLRecurse(highed.meta.optionsExtended.options);

    // Set fontfamilies
    // Remove deleted families and colors
    this.updateFontFamilies(config?.options?.subtitle?.style, this.defaultFF, highed.meta.colors, this.defaultColor, 'subtitle.style');

    // Title
    this.updateFontFamilies(config?.options?.title?.style, this.defaultFF, highed.meta.colors, this.defaultColor, 'title.style');

    // Axe X
    this.updateFontFamilies(
      JSON.parse(highedOptions[this.axesCat][0].options[0].defaults),
      this.defaultFF,
      highed.meta.colors,
      this.defaultColor,
      'xAxis.title.style'
    );

    this.updateFontFamilies(
      config.options.xAxis[0].labels?.style,
      this.defaultFF,
      highed.meta.colors,
      this.defaultColor,
      'xAxis.labels.style'
    );

    // Axe Y
    this.updateFontFamilies(
      config.options.yAxis[0].title?.style,
      this.defaultFF,
      highed.meta.colors,
      this.defaultColor,
      'yAxis.title.style'
    );

    this.updateFontFamilies(
      config.options.yAxis[0].labels?.style,
      this.defaultFF,
      highed.meta.colors,
      this.defaultColor,
      'yAxis.labels.style'
    );

    // Legend
    this.updateFontFamilies(config.options.legend.itemStyle, this.defaultFF, highed.meta.colors, this.defaultColor, 'legend.itemStyle');

    // Set colors
    // remove deleted colors
    let seriesColors = config?.options?.colors;
    seriesColors = seriesColors.filter((hexCode: string) => highed.meta.colors.includes(hexCode.toLowerCase()));

    // set plot border color
    let plotColor = highedOptions[this.chartCat][2].options[8].defaults;
    plotColor = highed.meta.colors.includes(plotColor?.toLowerCase()) ? plotColor.toLowerCase() : this.defaultColor;
    setTimeout(() => {
      events.emit('PropertyChange', 'chart.plotBorderColor', plotColor, 0);
    });

    // set border color
    let borderColor = highedOptions[this.chartCat][2].options[2].defaults;
    borderColor = highed.meta.colors.includes(borderColor?.toLowerCase()) ? borderColor.toLowerCase() : this.defaultColor;
    setTimeout(() => {
      events.emit('PropertyChange', 'chart.borderColor', borderColor, 0);
    });

    // background color, if deleted set to white
    let bgColor = config?.options?.chart?.backgroundColor ?? '';
    if (!bgColor) {
      bgColor = highedOptions[this.chartCat][2].options[1].defaults;
      bgColor = highed.meta.colors.includes(bgColor?.toLowerCase()) ? bgColor.toLowerCase() : '#ffffff';
    }

    setTimeout(() => {
      events.emit('PropertyChange', 'chart.backgroundColor', bgColor, 0);
    });

    // Insert new color picker field
    const colors = highed.dom.get('colors');
    const container = colors.parentElement.parentElement.parentElement;
    const newColors = highed.InspectorField(
      'array<color>',
      seriesColors,
      {
        title: 'colors',
        tooltip: false,
        values: seriesColors,
        custom: {},
        defaults: seriesColors,
        attributes: [],
      },
      (newValue: any) => {
        newValue = newValue.map((val: string) => (val === '#0' ? '#000000' : val));
        events.emit('PropertyChange', 'colors', newValue, 0);
      },
      true,
      'colors'
    );
    setTimeout(() => {
      events.emit('PropertyChange', 'colors', seriesColors, 0);
    });
    container.insertBefore(newColors, colors.parentElement.parentElement);
    colors.parentElement.parentElement.remove();
  }

  private forceReloadTable(newChartConfig: any): void {
    newChartConfig = { ...newChartConfig };
    const chartConfig = this.highchartModel.editor.chart.toProject();
    newChartConfig.options.data = chartConfig.options.data;

    if (chartConfig.options.data?.csv) {
      newChartConfig.settings.dataProvider.csv = chartConfig.options.data.csv;
    } else if (chartConfig.settings?.dataProvider?.csv) {
      const csvSplit = chartConfig.settings.dataProvider.csv.split('\n');
      newChartConfig.settings.dataProvider.csv = csvSplit[0];
    }
    this.highchartModel.editor.chart.loadProject(newChartConfig);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public updateFontFamilies(object: any, defaultff: string, colorArray: string[], defaultColor: string, objectName: string): void {
    if (object) {
      defaultff = defaultff ?? '';
      const fontfamily = highed.meta.fonts.includes(object.fontFamily) ? object.fontFamily : defaultff;
      const color = colorArray.includes(object.color?.toLowerCase()) ? object.color.toLowerCase() : defaultColor;

      // Mantis-3270: Prevent reload if not changes
      if ((color !== object.color?.toLowerCase() || fontfamily !== object.fontFamily) && objectName !== 'yAxis.title.style') {
        setTimeout(() => {
          this.highchartModel.editor.events.emit(
            'PropertyChange',
            objectName,
            { fontFamily: fontfamily, color, fontSize: object.fontSize },
            0
          );
        });
      }

      if (objectName === 'yAxis.title.style') {
        // Update interface
        const yAxisContainer = highed.dom.get('yAxis-title--style_container');

        const fontFamilyField = yAxisContainer.getElementsByClassName('highed-dropdown-body')[0];
        fontFamilyField.innerText = fontfamily;
        fontFamilyField.value = fontfamily;

        const fontSizeField = yAxisContainer.getElementsByClassName('highed-font-size')[0];
        fontSizeField.value = parseFloat(object.fontSize);
        fontSizeField.step = 0.5;

        const fontColorField = yAxisContainer.getElementsByClassName('font-color')[0];
        fontColorField.style.background = color;

        this.highchartModel.editor.events.emit(
          'PropertyChange',
          objectName,
          { fontFamily: fontfamily, color, fontSize: object.fontSize, fontStyle: object.fontStyle, fontWeight: object.fontWeight },
          0
        );
      }
    }
  }
}
