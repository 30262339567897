import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  ViewChild,
  Renderer2,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT_NAME_REMINDER_EDITOR_TOOLBAR_AUTH } from 'app/html-editor/plugins/document-name-reminder-editor/document-name-reminder-editor-authorities.util';

import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import { JhiLanguageService } from 'app/shared/jhipster/service/language.service';

import { IDocumentNameReminder } from 'app/shared/model/page-template/page-background.model';
import { IStyle } from 'app/shared/model/style.model';
import { StyleCategory } from 'app/shared/enum/style-category.enum';
import { AccountService } from 'app/core/auth/account.service';

@Component({
  selector: 'jhi-document-name-reminder-editor',
  templateUrl: 'document-name-reminder-editor.component.html',
  styleUrls: ['document-name-reminder-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentNameReminderEditorComponent implements AfterViewInit {
  @Input()
  public contextConfiguration: any;

  private _documentNameReminder: IDocumentNameReminder | null;
  @Input()
  set documentNameReminder(reminder: IDocumentNameReminder | null) {
    this._documentNameReminder = reminder;
    this.documentNameReminderEditorInstance?.setData(this.valueForCKEditor());
  }

  get documentNameReminder(): IDocumentNameReminder | null {
    return this._documentNameReminder;
  }

  @Input()
  public currentZoom = 100;

  @Input()
  set documentStyles(documentStyles: IStyle[]) {
    this._documentStyles = documentStyles;
    const charStyle = this._documentStyles?.find(style => style.category === StyleCategory.CHARACTERS);
    this.markerStyle = charStyle?.childrenStyles?.find(childStyle => childStyle.category === StyleCategory.CHARACTERS) ?? ({} as IStyle);
  }

  @Output()
  public onDocumentNameReminderChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public onDocumentNameReminderReset: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onDocumentNameReminderCancel: EventEmitter<void> = new EventEmitter<void>();

  public markerStyle: IStyle;

  public isDisplayToolbar = true;

  @ViewChild('documentNameReminderEditorToolbarElement', { static: false })
  documentNameReminderEditorToolbarElement: ElementRef<HTMLElement>;

  @ViewChild('documentNameReminderEditorElement', { static: false }) documentNameReminderEditorElement: ElementRef<HTMLElement>;

  public documentNameReminderEditorInstance: any;
  public hasEditorDataChanged = false;
  private _documentStyles: IStyle[] = [];
  private _developmentMode = false;

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    private languageService: JhiLanguageService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private accountService: AccountService
  ) {}

  public ngAfterViewInit(): void {
    import(`../../../../content/editor/build/translations/${this.languageService.getCurrentLanguage()}`).then(() => {
      CKEditor.addCKEditorTranslations(
        this.translateService.currentLang,
        this.translateService.translations[this.translateService.currentLang].ckeditor
      );
      this.edit();
    });
  }

  public edit(): void {
    if (!this.documentNameReminder) {
      return;
    }
    this.hasEditorDataChanged = false;
    this._createDocumentNameReminderEditor(this.documentNameReminderEditorElement.nativeElement, this.valueForCKEditor());
    this.changeDetectorRef.detectChanges();
  }

  public save(): void {
    if (!this.documentNameReminder) {
      return;
    }

    const documentNameReminder = this.documentNameReminderEditorInstance
      .getData()
      .replaceAll(new RegExp('<p([^>]+)>|</p>', 'g'), ' ')
      .replaceAll('&nbsp;', ' ')
      .replace(/ +(?= )/g, '')
      .trim();

    this.onDocumentNameReminderChange.emit(documentNameReminder);
  }

  public reset(): void {
    this.onDocumentNameReminderReset.emit();
  }

  public cancel(): void {
    this.onDocumentNameReminderCancel.emit();
  }

  private _createDocumentNameReminderEditor(editorElement: HTMLElement, documentName: string): void {
    CKEditor.DocumentNameReminderEditor.create(editorElement, {
      ...this.contextConfiguration,
      initialData: documentName,
      styles: { list: this._documentStyles },
      toolbar: this.buildToolbar(),
      link: { defaultProtocol: 'https://' },
    }).then((editor: any) => {
      this.documentNameReminderEditorInstance = editor;
      // Add toolbar to view
      this.renderer.appendChild(
        this.documentNameReminderEditorToolbarElement.nativeElement,
        this.documentNameReminderEditorInstance.ui.view.toolbar.element
      );
      // Detect change to enable validate btn
      this.documentNameReminderEditorInstance.model.document.on('change:data', () => {
        this.hasEditorDataChanged = true;
        this.changeDetectorRef.markForCheck();
      });
      // Disable browser spellcheck and create WSC instance
      this.documentNameReminderEditorInstance.editing.view.change((writer: any) => {
        writer.setAttribute('spellcheck', 'false', this.documentNameReminderEditorInstance.editing.view.document.getRoot());
      });

      this.documentNameReminderEditorInstance.editing.view.focus();
      if (this._developmentMode) {
        CKEditorInspector.attach(this.documentNameReminderEditorInstance);
      }

      editor.on('destroy', () => {
        if (this.documentNameReminderEditorInstance && this.documentNameReminderEditorInstance.state !== 'destroyed') {
          this.documentNameReminderEditorInstance.destroy().then(() => (this.documentNameReminderEditorInstance = null));
        }
      });
    });
  }

  private buildToolbar(): string[] {
    const result = DOCUMENT_NAME_REMINDER_EDITOR_TOOLBAR_AUTH.filter(
      (toolbarItem: any) => !toolbarItem.hasAnyAuthorities || this.accountService.hasAnyAuthority(toolbarItem.hasAnyAuthorities)
    ).map((toolbarItem: any) => toolbarItem.value);
    if (!result.filter(item => item !== '|')?.length) {
      this.isDisplayToolbar = false;
      this.changeDetectorRef.markForCheck();
    }
    return result;
  }

  private valueForCKEditor(): string {
    if (!this.documentNameReminder) {
      return '';
    }

    return this.documentNameReminder.alternativeTitle ?? this.documentNameReminder.name;
  }
}
