import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { publishReplay, refCount } from 'rxjs/operators';

export class Configuration {
  constructor(
    public sppUri: string,
    public appVersion: string,
    public brokerLogin: string,
    public brokerPassword: string,
    public brokerPrefix: string,
    public websocketsHost: string,
    public ckeditorLicenseKey: string,
    public pdfExportDisablePriority: boolean,
    public serverTimeZone: string
  ) {}
}

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private obsData: Observable<Configuration>;
  public resourceUrl = `${SERVER_API_URL}api/configuration`;

  constructor(private http: HttpClient) {}

  getConfiguration(): Observable<Configuration> {
    if (!this.obsData) {
      this.obsData = this.http.get<Configuration>(this.resourceUrl).pipe(publishReplay(1), refCount());
    }
    return this.obsData;
  }
}
