import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DocumentaryUnitService } from 'app/core/service/documentary-unit.service';

import { IUser } from 'app/core/user/user.model';
import * as moment from 'moment';
import { IFilters } from 'app/shared/model/version-history.model';

import { TranslateService } from '@ngx-translate/core';
import { ContentType } from 'app/shared/enum/content-type.enum';
import { IDocumentaryUnit } from 'app/shared/model/documentary-unit.model';
import { DateUtils } from 'app/shared/util/date-utils';

@Component({
  selector: 'jhi-version-filter-form',
  templateUrl: './version-filter-form.component.html',
  styleUrls: ['./version-filter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionFilterFormComponent implements OnInit {
  @Input() public sections: IDocumentaryUnit[];
  @Output() public filterHistory = new EventEmitter<IFilters>();

  public readonly ContentType = ContentType;
  public users: IUser[];
  public actualDate: string;

  public showTypeFilters = false; // Set to true to see filters
  public versionForm = this.fb.group({
    typeShapingSelected: this.fb.control(false),
    periodDateStart: this.fb.control(null),
    periodDateEnd: this.fb.control(null),
    periodHourStart: new UntypedFormControl({ value: null, disabled: true }),
    periodHourEnd: new UntypedFormControl({ value: null, disabled: true }),
    sections: this.fb.control(null),
    users: this.fb.control(null),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private documentaryUnitService: DocumentaryUnitService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.documentaryUnitService.getUsers().subscribe((users: IUser[]) => {
      this.users = users;
      this.changeDetectorRef.markForCheck();
    });
    this.versionForm.markAsDirty();
    this.actualDate = moment().format('YYYY-MM-DD HH:mm');
  }

  public periodDateStartChanged(value: string): void {
    if (value) {
      this.versionForm.get('periodHourStart')?.enable();
    } else {
      this.versionForm.get('periodHourStart')?.setValue(null);
      this.versionForm.get('periodHourStart')?.disable();
    }
  }

  public periodDateEndChanged(value: string): void {
    if (value) {
      this.versionForm.get('periodHourEnd')?.enable();
    } else {
      this.versionForm.get('periodHourEnd')?.setValue(null);
      this.versionForm.get('periodHourEnd')?.disable();
    }
  }

  public applyFilters(): void {
    const typesModification = ['SPLIT_CONTENT'];
    if (this.versionForm.get('typeShapingSelected')?.value) {
      typesModification.push('SHAPING');
    }

    // Extract lastName and firstName of the users
    const users: string[] = [];
    const _users: IUser[] = this.versionForm.get('users')?.value;
    if (_users) {
      _users.forEach(user => users.push(`${user?.firstName} ${user?.lastName}`));
    }

    this.filterHistory.emit({
      since: this.getTimestamp(this.versionForm.get('periodDateStart')?.value, this.versionForm.get('periodHourStart')?.value),
      until: this.getTimestamp(this.versionForm.get('periodDateEnd')?.value, this.versionForm.get('periodHourEnd')?.value, true),
      untilFilledByUser: !!this.versionForm.get('periodDateEnd')?.value,
      typesModification,
      users,
      currentLang: this.translateService.currentLang,
      sections: this.versionForm.get('sections')?.value,
    });

    this.versionForm.markAsPristine();
  }

  private getTimestamp(date: string, hour: string, isDateEnd = false): string {
    if (date) {
      if (!hour) {
        hour = isDateEnd ? '23:59' : '00:00';
      }
      const dateTime = moment(`${date} ${hour}`, 'YYYY-MM-DD HH:mm').tz(DateUtils.getServerTimezone(), true);
      return dateTime.format('x');
    } else if (isDateEnd) {
      return moment().tz(DateUtils.getServerTimezone(), true).format('x');
    }
    return '';
  }

  public getMaxDate(): string {
    if (this.versionForm.get('periodDateEnd')?.value) {
      return this.versionForm.get('periodDateEnd')?.value;
    }
    return this.actualDate;
  }
}
