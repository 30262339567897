import DOMPurify from 'dompurify';
export const sanitizeHTMLRecurse = o => {
  if (typeof o === 'string') {
    return DOMPurify.sanitize(o);
  }

  Object.keys(o).forEach(key => {
    if (typeof o[key] === 'string') {
      o[key] = DOMPurify.sanitize(o[key]);
    } else if (Array.isArray(o[key])) {
      o[key].forEach((item, i) => {
        o[key][i] = sanitizeHTMLRecurse(item);
      });
    } else if (typeof o[key] === 'object') {
      o[key] = sanitizeHTMLRecurse(o[key]);
    }
  });
  return o;
};
