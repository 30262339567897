import { Authority } from 'app/shared/enum/authority.enum';

export interface IMenu {
  name: string;
  children: any;
  toggleChildren: boolean;
  showChildren?: boolean;
  icon: string;
  authorities: string[];
}

export interface ISubMenu {
  name: string;
  icon: string;
  urlSegment: string;
  authorities: string[];
}

/** Style Editor */
export enum MenuStyleEditor {
  IMPORT,
  COLORS,
  COLORS_MATRIX,
  FONTS,
  FONT_FAMILIES,
  TEMPLATE_STYLE,
  PAGE_TEMPLATES,
  TABLE_MODELER,
  HIGHCHART_CONFIG,
  XHTML_STYLE,
}

type MenuStyleTemplatesSubMenu = {
  TITLE: ISubMenu;
  PARAGRAPH: ISubMenu;
  CHARACTERS: ISubMenu;
  LIST: ISubMenu;
  SUMMARY: ISubMenu;
  FOOTNOTE: ISubMenu;
  BORDER: ISubMenu;
  CLEANING: ISubMenu;
};

const STYLE_TEMPLATES_SUBMENU: MenuStyleTemplatesSubMenu = {
  TITLE: {
    name: 'template-style_title',
    icon: 'title',
    urlSegment: 'title',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  PARAGRAPH: {
    name: 'template-style_paragraph',
    icon: 'paragraph',
    urlSegment: 'paragraph',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  CHARACTERS: {
    name: 'template-style_characters',
    icon: 'fonts',
    urlSegment: 'characters',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  LIST: {
    name: 'template-style_list',
    icon: 'list_ol',
    urlSegment: 'list',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  SUMMARY: {
    name: 'template-style_summary',
    icon: 'summary',
    urlSegment: 'summary',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  FOOTNOTE: {
    name: 'template-style_footnote',
    icon: 'footnote',
    urlSegment: 'footnote',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  BORDER: {
    name: 'template-style_border',
    icon: 'border',
    urlSegment: 'border',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  CLEANING: {
    name: 'template-style_cleaning',
    icon: 'trash',
    urlSegment: 'cleaning',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
};

type StyleEditorMenuPageTemplatesSubMenu = {
  PAGE: ISubMenu;
  BACKGROUND: ISubMenu;
  REMINDER: ISubMenu;
};

const PAGE_TEMPLATES_SUBMENU: StyleEditorMenuPageTemplatesSubMenu = {
  PAGE: {
    name: 'page-templates_pages',
    icon: 'page-templates-pages',
    urlSegment: 'pages',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  BACKGROUND: {
    name: 'page-templates_backgrounds',
    icon: 'page-templates-backgrounds',
    urlSegment: 'backgrounds',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
  REMINDER: {
    name: 'page-templates_reminder',
    icon: 'page-templates-reminder',
    urlSegment: 'document-name-reminder',
    authorities: [Authority.UPDATE_STYLE_MODEL],
  },
};

const PROJECT_CONFIGURATION_SUBMENU: any = {
  PROJECT: {
    name: 'project-configuration_project',
    urlSegment: 'project',
    authorities: [Authority.UPDATE_DATA_CONFIGURATION],
  },
  DATES: {
    name: 'project-configuration_dates',
    urlSegment: 'dates',
    authorities: [Authority.UPDATE_DATA_CONFIGURATION],
  },
  UNITS: {
    name: 'project-configuration_units',
    urlSegment: 'units',
    authorities: [Authority.UPDATE_DATA_CONFIGURATION],
  },
  FORMAT: {
    name: 'project-configuration_format',
    urlSegment: 'format',
    authorities: [Authority.UPDATE_DATA_CONFIGURATION],
  },
  SEPARATORS: {
    name: 'project-configuration_separators',
    urlSegment: 'separators',
    authorities: [Authority.ACCESS_ADMIN],
  },
  LORE: {
    name: 'project-configuration_lore',
    urlSegment: 'lore',
    authorities: [Authority.ACCESS_ADMIN],
  },
};

const CSS_OVERRIDE_SUBMENU: any = {
  PROJECT: {
    name: 'project-css',
    urlSegment: 'project-css',
    authorities: [Authority.SUPER_ADMIN],
  },
  Global: {
    name: 'global-css-entry',
    urlSegment: 'global-css-entry',
    authorities: [Authority.SUPER_ADMIN],
  },
};

const LABEL_SUBMENU: any = {
  LABELS: {
    name: 'labels',
    urlSegment: 'labels',
    icon: 'table-labels',
    authorities: [Authority.IMPORT_EXPORT_NOMENCLATURE_FILE],
  },
  NOM_FDP: {
    name: 'nom-page-model',
    urlSegment: 'nom-page-model',
    icon: 'page-models',
    authorities: [Authority.IMPORT_EXPORT_NOMENCLATURE_FILE],
  },
  NOM_STYLES: {
    name: 'nom-styles',
    urlSegment: 'nom-styles',
    icon: 'nomenclature-styles',
    authorities: [Authority.IMPORT_EXPORT_NOMENCLATURE_FILE],
  },
};

const IMPORT_EXPORT_SUBMENU = (isAssetManagement: boolean) => {
  return {
    GABARIT: {
      name: 'template-import-export',
      urlSegment: 'template',
      authorities: [Authority.SUPER_ADMIN],
    },
    ...(!isAssetManagement && {
      PROJECT: {
        name: 'project-import-export',
        urlSegment: 'full-project',
        authorities: [Authority.SUPER_ADMIN],
      },
    }),
  };
};

/** Admin Editor */
export enum MenuAdminEditor {
  NOMS,
  XLIFF,
  MACRO_TAGS,
  SEPARATORS_IMPORT,
  PROJECT_CONFIGURATION,
  CSS_OVERRIDE,
  DOCUMENTARY_UNIT_HTML_EDITOR,
  USER_LIST,
  EXPORT_MONITORING,
  PROJECT_IMPORT_EXPORT,
}

/** Fonctions */
export function getMenuStyleEditor(menu: MenuStyleEditor): IMenu {
  switch (menu) {
    case MenuStyleEditor.IMPORT:
      return { name: 'import', children: null, toggleChildren: false, icon: 'import-css', authorities: [Authority.UPDATE_STYLE_MODEL] };
    case MenuStyleEditor.XHTML_STYLE:
      return {
        name: 'xhtml-style',
        children: null,
        toggleChildren: false,
        icon: 'xhtml-style',
        authorities: [Authority.UPDATE_STYLE_MODEL],
      };
    case MenuStyleEditor.HIGHCHART_CONFIG:
      return {
        name: 'highchart-config',
        children: null,
        toggleChildren: false,
        icon: 'graphics',
        authorities: [Authority.UPDATE_GRAPH_MODEL],
      };
    case MenuStyleEditor.COLORS:
    default:
      return { name: 'colors', children: null, toggleChildren: false, icon: 'colors', authorities: [Authority.UPDATE_STYLE_MODEL] };
    case MenuStyleEditor.COLORS_MATRIX:
      return {
        name: 'colors-matrix',
        children: null,
        toggleChildren: false,
        icon: 'colors-matrix',
        authorities: [Authority.UPDATE_STYLE_MODEL],
      };
    case MenuStyleEditor.FONTS:
      return { name: 'fonts', children: null, toggleChildren: false, icon: 'fonts', authorities: [Authority.UPDATE_STYLE_MODEL] };
    case MenuStyleEditor.FONT_FAMILIES:
      return {
        name: 'font-families',
        children: null,
        toggleChildren: false,
        icon: 'font-families',
        authorities: [Authority.UPDATE_STYLE_MODEL],
      };
    case MenuStyleEditor.TEMPLATE_STYLE:
      return {
        name: 'template-style',
        children: STYLE_TEMPLATES_SUBMENU,
        toggleChildren: true,
        icon: 'gabarit',
        authorities: [Authority.UPDATE_STYLE_MODEL],
      };
    case MenuStyleEditor.TABLE_MODELER:
      return {
        name: 'table-modeler',
        children: null,
        toggleChildren: false,
        icon: 'table-modeler',
        authorities: [Authority.UPDATE_STYLE_MODEL],
      };
    case MenuStyleEditor.PAGE_TEMPLATES:
      return {
        name: 'page-templates',
        children: PAGE_TEMPLATES_SUBMENU,
        toggleChildren: true,
        icon: 'page-section',
        authorities: [Authority.UPDATE_STYLE_MODEL],
      };
  }
}

export function getMenuAdminEditor(menu: MenuAdminEditor, isAssetManagement: boolean = false): IMenu {
  switch (menu) {
    case MenuAdminEditor.XLIFF:
      return { name: 'import-xliff', children: null, toggleChildren: false, icon: 'xliff', authorities: [Authority.IMPORT_EXPORT_XLIFF] };
    case MenuAdminEditor.MACRO_TAGS:
      return {
        name: 'import-macro-tags',
        children: null,
        toggleChildren: false,
        icon: 'macro-tags',
        authorities: [Authority.IMPORT_EXPORT_MACRO_TAGGING],
      };
    case MenuAdminEditor.NOMS:
      return {
        name: 'noms',
        children: LABEL_SUBMENU,
        toggleChildren: true,
        showChildren: false,
        icon: 'page-models',
        authorities: [Authority.IMPORT_EXPORT_NOMENCLATURE_FILE],
      };
    case MenuAdminEditor.PROJECT_CONFIGURATION:
    default:
      return {
        name: 'project-configuration',
        children: PROJECT_CONFIGURATION_SUBMENU,
        toggleChildren: true,
        icon: 'project-configuration',
        authorities: [Authority.UPDATE_DATA_CONFIGURATION, Authority.ACCESS_ADMIN],
      };

    case MenuAdminEditor.CSS_OVERRIDE:
      return {
        name: 'css-override',
        children: CSS_OVERRIDE_SUBMENU,
        toggleChildren: true,
        showChildren: false,
        icon: 'project-css',
        authorities: [Authority.SUPER_ADMIN],
      };

    case MenuAdminEditor.DOCUMENTARY_UNIT_HTML_EDITOR:
      return {
        name: 'documentary-unit-html-editor',
        children: null,
        toggleChildren: false,
        icon: 'html-ud',
        authorities: [Authority.SUPER_ADMIN],
      };

    case MenuAdminEditor.USER_LIST:
      return {
        name: 'user-list',
        children: null,
        toggleChildren: false,
        icon: 'user-list',
        authorities: [Authority.SUPER_ADMIN],
      };

    case MenuAdminEditor.SEPARATORS_IMPORT:
      return {
        name: 'import-separators',
        children: null,
        toggleChildren: false,
        icon: 'separator',
        authorities: [Authority.IMPORT_SEPARATORS],
      };

    case MenuAdminEditor.EXPORT_MONITORING:
      return {
        name: 'export-monitoring',
        children: null,
        toggleChildren: false,
        icon: 'export-monitoring',
        authorities: [Authority.SUPER_ADMIN],
      };

    case MenuAdminEditor.PROJECT_IMPORT_EXPORT:
      return {
        name: 'import-export',
        children: IMPORT_EXPORT_SUBMENU(isAssetManagement),
        toggleChildren: true,
        icon: 'project-import-export',
        authorities: [Authority.SUPER_ADMIN],
      };
  }
}

export function getMenuItems(type: string, isAssetManagement = false): IMenu[] {
  switch (type) {
    case 'styleEditor':
    default:
      return Object.entries(MenuStyleEditor)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([, value]) => getMenuStyleEditor(MenuStyleEditor[value]));

    case 'adminEditor':
      return Object.entries(MenuAdminEditor)
        .filter(([key]) => !isNaN(Number(key)))
        .map(([, value]) => getMenuAdminEditor(MenuAdminEditor[value], isAssetManagement));
  }
}
