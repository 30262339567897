<div class="page-not-accessible">
  <div class="content">
    <jhi-account-button [showDefault]="false"></jhi-account-button>

    <div class="center-content">
      <div class="action-content">
        <div
          *ngIf="projectStatus === ProjectProgressStatusEnum.DONE"
          class="general-message"
          [jhiTranslate]="isOnlyTemplate ? 'projectProgress.template-done' : 'projectProgress.title-done'"
        ></div>
        <div *ngIf="projectStatus === ProjectProgressStatusEnum.DONE" class="intro-message" jhiTranslate="projectProgress.intro-done"></div>
        <div
          *ngIf="projectStatus === ProjectProgressStatusEnum.IN_PROGRESS"
          class="general-message"
          [jhiTranslate]="isOnlyTemplate ? 'projectProgress.title-template' : 'projectProgress.title'"
        ></div>
        <div
          *ngIf="projectStatus === ProjectProgressStatusEnum.IN_PROGRESS"
          class="intro-message"
          [jhiTranslate]="isOnlyTemplate ? 'projectProgress.intro-template' : 'projectProgress.intro'"
        ></div>
        <div class="actions" *ngIf="projectStatus === ProjectProgressStatusEnum.IN_PROGRESS">
          <button mat-icon-button class="refresh" (click)="refresh()" color="primary">
            <mat-icon svgIcon="refresh"></mat-icon>
            <span jhiTranslate="projectProgress.refresh"></span>
          </button>
        </div>
        <div
          class="import-error"
          jhiTranslate="projectProgress.errorImport"
          *ngIf="projectStatus === ProjectProgressStatusEnum.ERROR"
        ></div>
        <div class="step-container">
          <div class="step-content" *ngFor="let step of progressStepStatus">
            <div class="step">{{ 'projectProgress.step.' + step | translate }}</div>
            <div class="status-icon-container">
              <jhi-loading [show]="true" *ngIf="progressStatus[step] === ProjectProgressStatusEnum.IN_PROGRESS"></jhi-loading>
              <mat-icon
                svgIcon="valid-30"
                *ngIf="progressStatus[step] === ProjectProgressStatusEnum.DONE || progressStatus[step] === ProjectProgressStatusEnum.VALID"
              ></mat-icon>
              <mat-icon svgIcon="error-30" *ngIf="progressStatus[step] === ProjectProgressStatusEnum.KO"></mat-icon>
            </div>
          </div>
        </div>
        <div class="link-row">
          <div class="link">
            <a class="cockpit" [href]="cockpitURL" rel="noopener noreferrer">
              <mat-icon svgIcon="cockpit"></mat-icon>
            </a>
            <span jhiTranslate="global.menu.cockpit"></span>
          </div>
          <ng-container *jhiHasAnyAuthority="[Authority.ACCESS_EOL_PAGE]">
            <div
              *ngIf="projectStatus === ProjectProgressStatusEnum.DONE && !checkIsNaN(this.accountService.documentId)"
              class="link"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a class="html-editor" (click)="navigate('html-editor')">
                <mat-icon svgIcon="eol-item"></mat-icon>
              </a>
              <span jhiTranslate="global.menu.htmlEditor"></span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="image-container">
        <img [src]="'/content/images/error/error_418_import_in_progress.svg'" [alt]="'page not accessible image'" />
      </div>
    </div>
  </div>
</div>
