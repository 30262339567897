import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import pubsub from 'app/pubsub';
import {
  EDITOR_PLUGIN_COLLAPSEDPANEL,
  EDITOR_PLUGIN_COLLAPSEPANEL,
  EDITOR_PLUGIN_TOGGLEPANEL,
  EDITOR_PLUGIN_OPENEDPANEL,
  EDITOR_PLUGIN_OPENPANEL,
  EDITOR_PLUGIN_CANTOPENPANEL,
} from 'app/pubsub.topics';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { LocalizeNumbersComponent } from './localize-numbers/localize-numbers.component';
import { DocumentExportComponent } from './document-export/document-export.component';
import { ConfigurationExportPdfComponent } from './document-export/configuration-export-pdf/configuration-export-pdf.component';
import { ConfigurationExportWordComponent } from './document-export/configuration-export-word/configuration-export-word.component';
import { ConfigurationExportLoreComponent } from './document-export/configuration-export-lore/configuration-export-lore.component';
import { DynamicDataPickingPluginComponent } from './dynamic-data/picking/dynamic-data-picking-plugin.component';
import { DynamicDataTaggingPluginComponent } from './dynamic-data/tagging/dynamic-data-tagging-plugin.component';
import { DynamicDataViewerPluginComponent } from './dynamic-data/viewer/dynamic-data-viewer-plugin.component';
import { FindReplaceComponent } from './find-replace/find-replace.component';
import { ImagePickerPluginComponent } from './image-picker/image-picker.component';
import { ImagePlaceholderPluginComponent } from './image-placeholder/image-placeholder.component';
import { PLUGIN_PANEL_COMPONENT_KEYS } from './plugin-panel-component-keys';
import { PluginComponent } from './plugin.component';
import { SpecialCharactersComponent } from './special-characters/special-characters.component';
import { StylingPanelComponent } from './styling/panel/styling-panel.component';
import { VersionsPanelComponent } from './versions/versions-panel.component';
import { HighChartPluginComponent } from './highchart/highchart.component';
import { SummaryPanelComponent } from './summary/summary-panel.component';
import { CommentsPanelComponent } from './comments/comments-panel.component';
import { TablePanelComponent } from './table/table-panel/table-panel.component';
import { TableAccessibilityPanelComponent } from './table/accessibility/accessibility.component';
import { ColumnPanelComponent } from './table/column-panel/column-panel.component';
import { RowPanelComponent } from './table/row-panel/row-panel.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { VersionsTrackingComponent } from './versions-tracking/versions-tracking.component';
import { AnnotateFactPanelComponent } from './dynamic-data/annotate/annotate-panel.component';
import { ConfigurationExportEsefComponent } from './document-export/configuration-export-esef/configuration-export-esef.component';
import { ConfigurationExportXliffComponent } from './document-export/configuration-export-xliff/configuration-export-xliff.component';
import { AnnotateDetailPanelComponent } from './dynamic-data/annotate/annotate-detail-panel/annotate-detail-panel.component';
import { LookupPanelComponent } from './lookup/lookup-panel.component';
import { GraphicalPagesComponent } from './graphical-pages/graphical-pages.component';
import { MacroTaggingPanelComponent } from './dynamic-data/macro-tagging/macro-tagging-panel.component';

@Injectable({
  providedIn: 'root',
})
export class PluginPanelService {
  public hideHeader = false;
  public panelTitle: string;
  private diffEditorState: Subject<boolean> = new Subject();
  private zoomSubject: Subject<number> = new Subject();

  private viewContainerRef: ViewContainerRef | null = null;
  private editor: any;
  private componentRef: ComponentRef<any> | undefined;
  private panelNav: MatSidenav | undefined;
  private panelOpened: BehaviorSubject<any> = new BehaviorSubject(false);
  private readonly pluginClassNameSource = new BehaviorSubject<string>('');
  readonly pluginClassName$ = this.pluginClassNameSource.asObservable();
  private zoom = 100;

  private components = {
    [PLUGIN_PANEL_COMPONENT_KEYS.IMAGE_PICKER]: ImagePickerPluginComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.IMAGE_PLACE_HOLDER]: ImagePlaceholderPluginComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.SPECIAL_CHARACTERS]: SpecialCharactersComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.LOCALIZE_NUMBERS]: LocalizeNumbersComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.DOCUMENT_EXPORT]: DocumentExportComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_PDF]: ConfigurationExportPdfComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_WORD]: ConfigurationExportWordComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_ESEF]: ConfigurationExportEsefComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_XLIFF]: ConfigurationExportXliffComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.CONFIGURATION_EXPORT_LORE]: ConfigurationExportLoreComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.DYNAMIC_DATA_PICKING]: DynamicDataPickingPluginComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.DYNAMIC_DATA_TAGGING]: DynamicDataTaggingPluginComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.DYNAMIC_DATA_VIEWER]: DynamicDataViewerPluginComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.FIND_REPLACE]: FindReplaceComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.STYLING]: StylingPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.SUMMARY]: SummaryPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.VERSION_HISTORY]: VersionsPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.HIGHCHART]: HighChartPluginComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.COMMENTS]: CommentsPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.TABLE_PANEL]: TablePanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.TABLE_ACCESSIBILITY_PANEL]: TableAccessibilityPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.COLUMN_PANEL]: ColumnPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.ROW_PANEL]: RowPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.IMPORT_FILE]: ImportFileComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.VERSION_TRACKING]: VersionsTrackingComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.ANNOTATE_FACT]: AnnotateFactPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.TAG_TEXT_BLOCK]: MacroTaggingPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.ANNOTATE_DETAIL_PANEL]: AnnotateDetailPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.LOOKUP]: LookupPanelComponent,
    [PLUGIN_PANEL_COMPONENT_KEYS.IMPORT_GRAPHICAL_PAGES]: GraphicalPagesComponent,
  };

  private editorChangeDetectorRef: ChangeDetectorRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    // here we are on a singleton service, .off is not necessary
    pubsub.on(EDITOR_PLUGIN_TOGGLEPANEL, ({ detail }: CustomEvent) => {
      const comp = this.components[detail.component];
      if (comp) {
        this.togglePluginPanel(comp, detail.title, detail.component, detail.hideHeader, detail.openPanel, detail.data);
      }
    });

    pubsub.on(EDITOR_PLUGIN_COLLAPSEPANEL, ({ detail }: CustomEvent) => {
      if (this.isThisComponentOpened(detail.component)) {
        this.collapsePluginPanel();
      }
    });

    pubsub.on(EDITOR_PLUGIN_OPENPANEL, ({ detail }: CustomEvent) => {
      const { condition, title, componentKey, hideHeader, openPanel } = detail;
      const { shouldBeClosedComponents, shouldBeOpened } = condition;
      const component = this.components[componentKey];
      const { name } = component;
      // Check if all components that should be closed are closed
      let closedConditionIsFulfil = true;
      if (shouldBeClosedComponents) {
        closedConditionIsFulfil = shouldBeClosedComponents.every((componentKey: string) => !this.isThisComponentOpened(componentKey));
      }

      // Check if all components that should be opened are opened
      let openedConditionIsFulfil = true;
      if (shouldBeOpened) {
        openedConditionIsFulfil = shouldBeOpened.every((componentKey: string) => this.isThisComponentOpened(componentKey));
      }
      if (closedConditionIsFulfil && openedConditionIsFulfil && !this.isThisComponentOpened(name)) {
        this.expandPluginPanel(component as unknown, title, componentKey, hideHeader, openPanel);
      } else {
        pubsub.fire(EDITOR_PLUGIN_CANTOPENPANEL, {
          isOpen: false,
          componentKey,
          channelId: this.editor.config.get('collaboration')?.channelId,
          editor: this.editor,
        });
      }
    });
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  setEditor(editor: any): void {
    this.editor = editor;
    if (this.componentRef?.instance) {
      this.collapsePluginPanel();
    }
  }

  setPanelNav(component: MatSidenav, editorChangeDetectorRef: ChangeDetectorRef): void {
    this.panelNav = component;
    this.editorChangeDetectorRef = editorChangeDetectorRef;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  isThisComponentOpened(key: string): boolean {
    return this.panelOpened.value && this.componentRef?.instance.componentKey === key;
  }

  toggleHeaderDisplay(hideHeader = false): void {
    this.hideHeader = hideHeader;
  }

  togglePluginPanel(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pluginComponent: any,
    title: string,
    componentKey: string,
    hideHeader = false,
    openPanel = true,
    data: any = null
  ): void {
    if (this.panelNav?.opened && this.componentRef && (this.panelTitle === title || !title)) {
      this.collapsePluginPanel();
    }

    if (this.viewContainerRef && this.editor) {
      this.expandPluginPanel(pluginComponent, title, componentKey, hideHeader, openPanel, data);
    }
  }

  collapsePluginPanel(): void {
    if (this.componentRef) {
      if (this.isThisComponentOpened(this.componentRef.instance.componentKey)) {
        this.closeDiff();
      }

      this.componentRef.destroy();
      delete this.componentRef;
      this.hideHeader = false;
      this.panelTitle = '';
    }
    this.panelNav?.close();
    this.panelOpened.next(false);
    pubsub.fire(EDITOR_PLUGIN_COLLAPSEDPANEL);

    this.editorChangeDetectorRef.markForCheck();
  }

  closePanel(): void {
    if (this.componentRef?.instance) {
      this.componentRef.instance.onClickClosePanel();
    } else {
      this.collapsePluginPanel();
    }
  }

  getComponentInstance(): PluginComponent {
    return this.componentRef?.instance;
  }

  getDiffStateObservable(): Observable<boolean> {
    return this.diffEditorState.asObservable();
  }

  openDiff(): void {
    this.diffEditorState.next(true);
  }

  closeDiff(): void {
    this.diffEditorState.next(false);
  }

  // Give zoom value to component
  public updateZoom(zoom: number) {
    this.zoom = zoom;
    this.zoomSubject.next(zoom);
    if (this.componentRef?.instance?.zoom) {
      this.componentRef.instance.zoom = zoom;
    }
  }

  getZoomObservable(): Observable<number> {
    return this.zoomSubject.asObservable();
  }

  private async expandPluginPanel(
    pluginComponent: any,
    title: string,
    componentKey: string,
    hideHeader = false,
    openPanel = true,
    data: any = null
  ): Promise<void> {
    this.viewContainerRef?.clear();
    // Create dynamically the plugin component inside the container

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(pluginComponent);
    this.componentRef = this.viewContainerRef?.createComponent(componentFactory);
    if (!this.componentRef) {
      return;
    }
    this.componentRef.instance.editor = this.editor;
    this.componentRef.instance.data = data;
    this.componentRef.instance.componentKey = componentKey;
    // Until editor is remove from pluginPanelService, use the instance below
    // Once removed (if we have time :p), we will have to pass isReadOnly/CommentsOnly.isEnabled through pubsub parameter of TOOGLE event
    this.componentRef.instance.isReadOnlyMode =
      this.editor.isReadOnly || (this.editor.plugins.has('CommentsOnly') && this.editor.plugins.get('CommentsOnly').isEnabled);
    this.pluginClassNameSource.next(pluginComponent.className);

    this.componentRef.instance.zoom = this.zoom;
    // Special case is HighChart doesn't need panel to be opened
    if (openPanel) {
      this.toggleHeaderDisplay(hideHeader);
      this.panelTitle = title;

      const result = await this.panelNav?.open();
      if (result === 'open') {
        pubsub.fire(EDITOR_PLUGIN_OPENEDPANEL, {
          isOpen: true,
          component: pluginComponent,
          title,
          channelId: this.editor.config.get('collaboration')?.channelId,
          editor: this.editor,
        });
      }

      this.panelOpened.next(true);
    }

    this.editorChangeDetectorRef.markForCheck();
  }
}
