import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'jhi-data-deletion-confirmation',
  templateUrl: 'deletion-confirmation.component.html',
  styleUrls: ['deletion-confirmation.component.scss'],
})
export class DeletionConfirmationComponent {
  public key: string;
  public name: string;
  public deleteMessage: string;
  public cancelMessage: string;

  constructor(
    public dialogRef: MatDialogRef<DeletionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public deletion: { type: string; name: string; deleteMessage?: string; cancelMessage?: string }
  ) {
    this.key = deletion.type;
    this.name = deletion.name;
    this.deleteMessage = deletion.deleteMessage ?? 'dialog.btn-delete';
    this.cancelMessage = deletion.cancelMessage ?? 'dialog.btn-cancel';
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }
}
