import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClassificationPlanService } from 'app/core/service/classification-plan.service';
import { ClassificationPLanDocumentDTO } from 'app/shared/model/fact.model';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, exhaustMap, filter, finalize, map, retry, share, switchMap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { IDocumentContext } from 'app/shared/model/document.model';
import { AccountService } from 'app/core/auth/account.service';
import { RightMode } from 'app/shared/enum/right-mode.enum';
import { EditorService } from 'app/core/service/editor.service';
import { DocumentaryUnitService, ITocConfig } from 'app/core/service/documentary-unit.service';
import { IDocumentaryUnit, ITitle } from 'app/shared/model/documentary-unit.model';
import { ContentType } from 'app/shared/enum/content-type.enum';
import { LoaderService } from 'app/core/service/loader.service';

import pubsub from 'app/pubsub';
import { NAVIGATE_TO_EDITOR, TOC_UPDATED, TOC_UPDATE_FROM_TITLE } from 'app/pubsub.topics';
import { Authority } from 'app/shared/enum/authority.enum';
import { ContextService } from 'app/core/service/context.service';
import { Router } from '@angular/router';
import { DocumentService } from 'app/core/service/document.service';
import { IDocumentProperties } from 'app/shared/model/document-properties.model';

class DocumentUnitTree {
  id: number;
  numbering: string;
  title: string;
  htmlContent: string;
  titleId: string;
  children: DocumentUnitTree[];
  parent: DocumentUnitTree;
  documentaryUnit: IDocumentaryUnit;
  documentaryUnitComputedWithTitle: IDocumentaryUnit;
  hasReadRight: boolean;
  hasWriteRight: boolean;
  wrongTitle: boolean;
  actionable: boolean;
  defaultTitleNumber?: number;
}
class TitleUpdate {
  uuid: string;
  titleNumbering: string;
  titleContent: string;
  titleHtmlContent: string;
  editorId: string;
}

@Component({
  selector: 'jhi-document-toc',
  templateUrl: './document-toc.component.html',
  styleUrls: ['./document-toc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTocComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public documentContext!: IDocumentContext;
  @Input() public openedEditors: string[];
  @Input() sectionId: number;
  @Input() titleId: string;
  @Input() properties: IDocumentProperties;

  @Output() public selected = new EventEmitter<IDocumentaryUnit>();
  @Output() public clicked = new EventEmitter<IDocumentaryUnit>();
  @Output() public closed = new EventEmitter<void>();
  @Output() public closeSection = new EventEmitter<string>();

  public readonly Authority = Authority;
  public treeControl = new NestedTreeControl<DocumentUnitTree>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<DocumentUnitTree>();
  public parentSectionId: number;
  public readonly ContentType = ContentType;

  public isExpanded: { [key: number]: boolean } = {};
  public editMode = false;
  public configMode = false;
  public addMode = false;
  public graphicalMode = false;
  public activationMode = false;
  public lockMode = false;
  public synchonizedDate: moment.Moment | null = null;
  public hasWrongTitle = false;
  public pageOffset: number;
  public classificationPLanForm: UntypedFormGroup;
  public classificationPLanDocuments: ClassificationPLanDocumentDTO[];

  private updateSelection = false;
  private subscriptions: Subscription = new Subscription();
  private documentaryUnits: IDocumentaryUnit[];
  private fetchToc$ = new Subject<void>();
  private titleUpdate$ = new Subject<TitleUpdate>();
  private titleUpdateRef: Function;
  private missingTitle: string;
  private hasDocumentUnitCreated = false;
  private hasDocumentUnitActivation = false;
  private udsToRemoveGraphicalPages = new Map();
  private hasDocumentUnitGraphicalRemoved = false;
  private pageOffsetChange: Subject<number> = new Subject<number>();
  private isSplit = false;

  public tocConfig: ITocConfig;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private documentaryUnitService: DocumentaryUnitService,
    private documentService: DocumentService,
    private classificationPlanService: ClassificationPlanService,
    private contextService: ContextService,
    private accountService: AccountService,
    private router: Router
  ) {
    this.missingTitle = this.translateService.instant('htmlEditor.toc.type.missing');
  }

  ngOnInit(): void {
    this.tocConfig = this.documentaryUnitService.getTocConfiguration();

    this.classificationPLanForm = new UntypedFormGroup({
      classificationPLan: new UntypedFormControl(this.contextService.currentDocumentContext.id),
    });
    this.classificationPlanService.getClassificationPlanDocuments().subscribe((classificationPLan: ClassificationPLanDocumentDTO[]) => {
      this.classificationPLanDocuments = classificationPLan;
    });

    this.classificationPLanForm.controls.classificationPLan.valueChanges.subscribe(value => this.openClassificationPLanDocument(value));

    this.startMonitoring();

    // Update TOC from document title
    this.subscriptions.add(this.titleUpdate$.pipe(debounceTime(100)).subscribe(data => this.titleUpdateFromDocument(data)));
    this.titleUpdateRef = ({ detail }: CustomEvent) => this.titleUpdate$.next(detail);
    pubsub.on(TOC_UPDATE_FROM_TITLE, this.titleUpdateRef);
    this.pageOffset = this.properties?.pageNumberOffset ?? 0;
    this.synchonizedDate = this.contextService.currentDocumentContext.summarySynchronizeDate;

    this.subscriptions.add(
      this.pageOffsetChange
        .pipe(
          debounceTime(1000),
          filter(pageOffset => pageOffset != null),
          map(pageOffset =>
            this.documentService.updateDocumentPageNumberOffset(pageOffset).subscribe(props => {
              this.pageOffset = props.pageNumberOffset ?? 0;
            })
          )
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    pubsub.off(TOC_UPDATE_FROM_TITLE, this.titleUpdateRef);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.sectionId) {
      this.changeSelection();
    }
  }

  public openClassificationPLanDocument(documentId: number, newTab = false): void {
    if (newTab) {
      window.open(`https://${window.location.host}/document/${documentId}`, '_blank');
    } else {
      window.location.href = `/document/${documentId}`;
    }
  }

  public hasChild = (_: number, node: DocumentUnitTree): boolean => node.children != null;

  public toggleConfigMode(): void {
    // edit mode false to ensure state consistency
    this.editMode = false;
    this.configMode = !this.configMode;
    if (!this.configMode) {
      this.documentaryUnitService.setTocConfiguration(this.tocConfig);
      this.refreshTree();
    }
  }

  public toggleEditMode(): void {
    // configMode mode false to ensure state consistency
    this.configMode = false;
    this.editMode = !this.editMode;
    this.addMode = false;
    this.lockMode = false;
    if (this.activationMode) {
      this.activationMode = false;
      this.refreshTree();
    }
    if (this.graphicalMode) {
      if (this.hasDocumentUnitGraphicalRemoved && this.udsToRemoveGraphicalPages.size !== 0) {
        const udsModified = Array.from(this.udsToRemoveGraphicalPages.keys());
        this.loaderService.show();
        this.documentaryUnitService.removeGraphicalPageDocumentUnit(udsModified).subscribe(() => {
          // update documentaryUnits model to refresh toc
          this.documentaryUnits.forEach((documentaryUnit: IDocumentaryUnit) => {
            if (udsModified.find(id => documentaryUnit.id === id)) {
              delete documentaryUnit.graphicalPagePdfName;
              delete documentaryUnit.graphicalPagesNumberOfPages;
            }
            // stop the loader after finishing refresh TOC
            this.loaderService.hide();
          });
        });
      }
      this.graphicalMode = false;
      this.hasDocumentUnitGraphicalRemoved = false;
      this.udsToRemoveGraphicalPages.clear();
    }
    this.cdr.markForCheck();
  }

  public toggleAddMode(): void {
    this.addMode = !this.addMode;
    this.lockMode = false;
    this.graphicalMode = false;
    if (this.activationMode) {
      this.activationMode = false;
      this.refreshTree();
    }
    this.cdr.markForCheck();
  }

  public toggleGraphicalMode(): void {
    this.graphicalMode = !this.graphicalMode;
    this.addMode = false;
    this.lockMode = false;
    if (this.activationMode) {
      this.activationMode = false;
      this.refreshTree();
    }
    this.cdr.markForCheck();
  }

  public toggleActivationMode(): void {
    this.activationMode = !this.activationMode;
    this.addMode = false;
    this.graphicalMode = false;
    this.lockMode = false;
    this.refreshTree();
    this.cdr.markForCheck();
  }

  public toggleLockMode(): void {
    this.lockMode = !this.lockMode;
    this.addMode = false;
    this.graphicalMode = false;
    if (this.activationMode) {
      this.activationMode = false;
      this.refreshTree();
    }
    this.cdr.markForCheck();
  }

  public synchronizeTOC(): void {
    if (!this.hasWrongTitle) {
      this.documentaryUnitService.synchronize().subscribe(date => {
        this.synchonizedDate = date;
        this.cdr.markForCheck();
      });
    }
  }

  public updateToc(isSplit = false): void {
    this.isSplit = isSplit;
    // Called after document saved
    this.fetchToc$.next();
    this.cdr.markForCheck();
  }

  private startMonitoring(): void {
    this.subscriptions.add(
      this.fetchToc$
        .pipe(
          switchMap(() => timer(100, 5000)),
          exhaustMap(() => this.getDocumentUnits()),
          retry(),
          share()
        )
        .subscribe(documentaryUnits => this.checkAndUpdateToc(documentaryUnits))
    );
    this.fetchToc$.next();
  }

  private getDocumentUnits(): Observable<IDocumentaryUnit[]> {
    const level = this.tocConfig?.includedTitleLevels?.length > 2 ? Math.max(...this.tocConfig.includedTitleLevels) : 2;
    return this.documentaryUnitService.getDocumentUnits(this.hasDocumentUnitCreated, false, null, level);
  }

  private refreshTree(): void {
    const treenodes: DocumentUnitTree[] = this.generateTree(this.documentaryUnits);
    let defaultTitleNumber = 0;
    treenodes.forEach((tree: DocumentUnitTree) => {
      // exclude intro and covers
      if (tree.children != null) {
        defaultTitleNumber += 1;
        tree.defaultTitleNumber = defaultTitleNumber;
      }
    });
    this.dataSource.data = treenodes;
    // Required for expandAll to work (see https://github.com/angular/components/issues/12469)
    this.treeControl.dataNodes = this.dataSource.data;
  }

  private checkAndUpdateToc(documentaryUnits: IDocumentaryUnit[]): void {
    this.checkTocUpdated(documentaryUnits).subscribe(updated => {
      if (this.isSplit) {
        pubsub.fire(NAVIGATE_TO_EDITOR, this.isSplit);
        this.isSplit = false;
      }

      if (!updated) {
        return;
      }

      this.documentaryUnits = documentaryUnits;
      this.refreshTree();
      if (this.hasDocumentUnitActivation) {
        this.hasDocumentUnitActivation = false;
        this.loaderService.hide();
      }
      if (this.titleId?.length && !this.findByTitleId(this.dataSource.data, this.titleId)) {
        this.titleId = '';
        this.parentSectionId = 0;
      }

      if (this.updateSelection || !this.sectionId) {
        this.changeSelection();
      } else if (!this.titleId) {
        const selectedSection = this.findById(this.dataSource.data, this.sectionId, '');
        if (selectedSection?.titleId) {
          this.titleId = selectedSection.titleId;
          if (selectedSection.parent) {
            this.isExpanded[selectedSection.parent.id] = true;
            this.parentSectionId = selectedSection.parent.id;
          }
        }
        this.cdr.markForCheck();
      }
    });
  }

  private checkTocTitleUpdated(oldTitles: ITitle[], newTitles: ITitle[]): boolean {
    if (oldTitles.length !== newTitles.length) {
      return true;
    }
    for (let i = 0; i < oldTitles.length; i++) {
      const oldTitle = oldTitles[i];
      const newTitle = newTitles[i];
      if (oldTitle.id !== newTitle.id || oldTitle.uuid !== newTitle.uuid) {
        return true;
      }
      if (oldTitle.position !== newTitle.position || oldTitle.level !== newTitle.level) {
        return true;
      }
      if (oldTitle.content !== newTitle.content) {
        return true;
      }
      if (oldTitle.numbering !== newTitle.numbering) {
        return true;
      }
    }
    return false;
  }

  private checkTocUpdated(documentaryUnits: IDocumentaryUnit[]): Observable<boolean> {
    if (!this.documentaryUnits || !documentaryUnits) {
      return of(true);
    }
    if (this.hasDocumentUnitCreated || this.documentaryUnits.length !== documentaryUnits.length) {
      this.hasDocumentUnitCreated = false;
      pubsub.fire(TOC_UPDATED);
      // new documentary unit added by a other user -> update the authorities
      return this.accountService.identity(true).pipe(map(() => true));
    }

    let diff = false;
    for (let i = 0; i < this.documentaryUnits.length; i++) {
      const oldDU = this.documentaryUnits[i];
      const newDU = documentaryUnits[i];
      if (oldDU.id !== newDU.id || oldDU.uuid !== newDU.uuid || oldDU.objectId !== newDU.objectId) {
        diff = true;
      }
      if (
        oldDU.active !== newDU.active ||
        oldDU.locked !== newDU.locked ||
        oldDU.position !== newDU.position ||
        oldDU.graphicalPagePdfName !== newDU.graphicalPagePdfName ||
        oldDU.graphicalPagesNumberOfPages !== newDU.graphicalPagesNumberOfPages
      ) {
        diff = true;
      }
      if (this.checkTocTitleUpdated(oldDU.titles, newDU.titles)) {
        diff = true;
      }
      if (diff) {
        pubsub.fire(TOC_UPDATED);
        break;
      }
    }
    return of(diff);
  }

  private generateSection(documentaryUnitTree: DocumentUnitTree): IDocumentaryUnit {
    return {
      id: documentaryUnitTree.id,
      defaultTitle: documentaryUnitTree.title,
      titleId: documentaryUnitTree.titleId,
      uuid: documentaryUnitTree.documentaryUnit.uuid,
      objectId: documentaryUnitTree.documentaryUnit.objectId,
      type: documentaryUnitTree.documentaryUnit.type,
      active: documentaryUnitTree.documentaryUnit.active,
      locked: documentaryUnitTree.documentaryUnit.locked,
      position: documentaryUnitTree.documentaryUnit.position,
      titles: documentaryUnitTree.documentaryUnit.titles,
      graphicalPagePdfName: documentaryUnitTree.documentaryUnit.graphicalPagePdfName,
      graphicalPagesNumberOfPages: documentaryUnitTree.documentaryUnit.graphicalPagesNumberOfPages,
      taggedAsGraphicalPage: documentaryUnitTree.documentaryUnit.taggedAsGraphicalPage,
    };
  }

  private generateDocumentUnitTree(documentaryUnit: IDocumentaryUnit, title: ITitle, addAfter: boolean): DocumentUnitTree {
    const treenode = new DocumentUnitTree();
    treenode.documentaryUnit = documentaryUnit;
    treenode.id = documentaryUnit.id ?? 0;
    treenode.titleId = title?.uuid;
    treenode.wrongTitle = false;

    if (documentaryUnit.type === ContentType.CHAPTER) {
      treenode.title = title?.content ?? documentaryUnit.defaultTitle ?? this.missingTitle;
      treenode.title = treenode.title.replace(/(<([^>]+)>)/g, '');
      treenode.actionable = addAfter;
      treenode.numbering = title?.numbering ?? '';
    } else if (documentaryUnit.type === ContentType.INTRODUCTION) {
      treenode.actionable = true;
      treenode.title = title?.content ?? this.translateService.instant('htmlEditor.toc.type.introduction');
    } else if (documentaryUnit.type === ContentType.COVER_I_II) {
      treenode.title = this.translateService.instant('htmlEditor.toc.type.covers12');
      treenode.actionable = false;
    } else if (documentaryUnit.type === ContentType.COVER_III_IV) {
      treenode.title = this.translateService.instant('htmlEditor.toc.type.covers34');
      treenode.actionable = false;
    } else {
      treenode.title = this.missingTitle;
      treenode.actionable = false;
    }

    treenode.hasReadRight = this.checkUDRights(treenode, RightMode.READ);
    treenode.hasWriteRight = this.checkUDRights(treenode, RightMode.WRITE);
    return treenode;
  }

  private generateFakeParent(treenode: DocumentUnitTree): DocumentUnitTree {
    const parentNode = this.generateDocumentUnitTree(treenode.documentaryUnit, {} as ITitle, false);
    parentNode.id = treenode.documentaryUnit.id ?? 0;
    parentNode.children = [];
    parentNode.title = this.missingTitle;
    parentNode.wrongTitle = !!treenode.documentaryUnit.active; // the inactive are not set as error
    parentNode.documentaryUnitComputedWithTitle = this.generateSection(treenode);
    return parentNode;
  }

  private generateEmptyNode(
    documentaryUnits: IDocumentaryUnit[],
    index: number,
    parentNode: DocumentUnitTree,
    treenodes: DocumentUnitTree[]
  ): DocumentUnitTree {
    const documentaryUnit: IDocumentaryUnit = documentaryUnits[index];
    const treenode = this.generateDocumentUnitTree(documentaryUnit, {} as ITitle, true);
    let newParentNode: any = null;
    if (documentaryUnit.type === ContentType.CHAPTER) {
      if (
        !parentNode ||
        !this.previousChaptersHaveTitles(documentaryUnits, index) ||
        !this.nextChaptersHaveTitles(documentaryUnits, index)
      ) {
        newParentNode = treenode;
        treenode.children = [];
        treenodes.push(treenode);
      } else {
        treenode.parent = parentNode;
        parentNode.children.push(treenode);
      }
    } else {
      treenodes.push(treenode);
    }
    treenode.documentaryUnitComputedWithTitle = this.generateSection(treenode);
    return newParentNode;
  }

  private nextChaptersHaveTitles(documentaryUnits: IDocumentaryUnit[], startIndex: number): boolean {
    // check if all next documentary units have no titles;
    for (let i = startIndex + 1; i < documentaryUnits.length; i++) {
      if (documentaryUnits[i].type === ContentType.CHAPTER && documentaryUnits[i].titles.length > 0) {
        return true;
      }
    }
    return false;
  }

  private previousChaptersHaveTitles(documentaryUnits: IDocumentaryUnit[], startIndex: number): boolean {
    // check if all next documentary units have no titles;
    for (let i = startIndex - 1; i > 0; i--) {
      if (documentaryUnits[i].type === ContentType.CHAPTER && documentaryUnits[i].titles.length > 0) {
        return true;
      }
    }
    return false;
  }

  private updateTitleWithParentNode(
    treenode: DocumentUnitTree,
    title: ITitle,
    parentNode: DocumentUnitTree,
    treenodes: DocumentUnitTree[],
    firstTitleInDocumentaryUnit = false
  ): DocumentUnitTree {
    let newParentNode: any = null;
    if (title.level === 1) {
      if (firstTitleInDocumentaryUnit && (!parentNode.children || treenode.documentaryUnit.active)) {
        newParentNode = treenode;
        newParentNode.children = [];
      } else {
        // The T1 must be at the first position, so it's a wrong position for this node
        treenode.wrongTitle = !!treenode.documentaryUnit.active; // the inactive are not set as error
        // and attach it to the first title T1
        treenode.parent = parentNode;
        parentNode.children.push(treenode);
      }
    } else if (firstTitleInDocumentaryUnit && (!parentNode.children || parentNode.documentaryUnit.type !== ContentType.CHAPTER)) {
      // It's the first chapter and not start with T1
      newParentNode = this.generateFakeParent(treenode);
      newParentNode.children.push(treenode);
      treenode.wrongTitle = !!treenode.documentaryUnit.active; // the inactive are not set as error
      treenode.parent = newParentNode;
    } else {
      treenode.parent = parentNode;
      parentNode.children.push(treenode);
    }

    if (newParentNode) {
      treenodes.push(newParentNode);
    }
    treenode.documentaryUnitComputedWithTitle = this.generateSection(treenode);
    return newParentNode;
  }

  private generateTree(documentaryUnits: IDocumentaryUnit[]): DocumentUnitTree[] {
    let treenodes: DocumentUnitTree[] = [];
    this.hasWrongTitle = false;
    if (documentaryUnits) {
      treenodes = this.generateNodeTree(documentaryUnits);
      this.hasWrongTitle = this.checkWrongTitle(treenodes);
      this.cdr.markForCheck();
    }
    this.documentaryUnitService.canSynchronize = !this.hasWrongTitle;
    return treenodes;
  }

  private checkWrongTitle(treenodes: DocumentUnitTree[]): boolean {
    for (const node of treenodes) {
      if (node.wrongTitle) {
        return true;
      }
      if (node.children) {
        for (const child of node.children) {
          if (child.wrongTitle) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private generateNodeTree(documentaryUnits: IDocumentaryUnit[]): DocumentUnitTree[] {
    const treenodes: DocumentUnitTree[] = [];
    let parentNode: DocumentUnitTree = {} as DocumentUnitTree;
    documentaryUnits.forEach((documentaryUnit: IDocumentaryUnit, idx: number) => {
      if (this.activationMode || documentaryUnit.active) {
        // Ignore the titles inside for COVERS
        if (documentaryUnit.titles?.length > 0 && documentaryUnit.type === ContentType.CHAPTER) {
          documentaryUnit.titles.forEach((title: ITitle, index: number, titles: ITitle[]) => {
            if (!this.tocConfig.includedTitleLevels.includes(title.level ?? 0) || this.tocConfig.excludedTitles.includes(title.id)) {
              return;
            }
            const treenode = this.generateDocumentUnitTree(documentaryUnit, title, index === titles.length - 1);
            const newParentNode = this.updateTitleWithParentNode(treenode, title, parentNode, treenodes, index === 0);
            if (newParentNode) {
              parentNode = newParentNode;
            }
          });
        } else {
          const newParentNode = this.generateEmptyNode(documentaryUnits, idx, parentNode, treenodes);
          if (newParentNode) {
            parentNode = newParentNode;
          }
        }
      }
    });
    return treenodes;
  }

  private changeSelection(): void {
    const treenodes = this.dataSource.data;
    if (treenodes?.length > 0) {
      this.updateSelection = false;
      const editorSectionId = sessionStorage.getItem('editor-section-id');
      const defaultSectionId = editorSectionId ? +editorSectionId : 0;
      const sectionId = this.sectionId ? +this.sectionId : defaultSectionId;
      if (!sectionId) {
        this.selectFirstSection(treenodes);
      } else {
        const selectedSection = this.findById(treenodes, sectionId, this.titleId);
        if (selectedSection) {
          this.selection(selectedSection);
        } else {
          this.selectFirstSection(treenodes);
        }
      }
    } else {
      this.updateSelection = true;
    }
  }

  public selection(documentUnitTree: DocumentUnitTree): void {
    this.sectionId = documentUnitTree.id;
    this.titleId = documentUnitTree.titleId;
    this.parentSectionId = documentUnitTree.parent?.id;
    if (documentUnitTree.parent) {
      this.isExpanded[documentUnitTree.parent.id] = true;
    }
    sessionStorage.setItem('editor-section-id', documentUnitTree.id.toString());
    this.selected.emit(documentUnitTree.documentaryUnitComputedWithTitle);
  }

  public close(): void {
    this.closed.emit();
  }

  public toggleNode(documentUnitTree: DocumentUnitTree): void {
    const hasValue = this.isExpanded[documentUnitTree.id] !== undefined;
    if (documentUnitTree.children?.length > 0) {
      this.isExpanded[documentUnitTree.id] = hasValue ? !this.isExpanded[documentUnitTree.id] : true;
    } else if (hasValue) {
      delete this.isExpanded[documentUnitTree.id];
    }
  }

  public onClickNode(documentUnitTree: DocumentUnitTree): void {
    if (this.checkUDRights(documentUnitTree, RightMode.READ)) {
      this.clicked.emit(documentUnitTree.documentaryUnitComputedWithTitle);
    }
  }

  public checkUDRights(documentUnitTree: DocumentUnitTree, right: RightMode): boolean {
    let readOrWriteAuthorityForSection;
    if (right === RightMode.READ) {
      // prefer to check UD read permission than ACCESS_SECTION permission to have same check than write but it works too
      readOrWriteAuthorityForSection = `UD_${documentUnitTree.id}_READ`;
    } else {
      // update_content permission is not permission set UD per UD but a global permission for USER
      // so prefer to check UD write permission
      readOrWriteAuthorityForSection = `UD_${documentUnitTree.id}_WRITE`;
    }

    return this.accountService.hasAnyAuthorityForSection([readOrWriteAuthorityForSection], documentUnitTree.id);
  }

  public updatePageOffset(): void {
    this.pageOffsetChange.next(this.pageOffset);
  }

  public getTooltip(element: HTMLElement, documentUnitTree: DocumentUnitTree): string | null {
    const truncated = element ? element.scrollWidth > element.clientWidth : false;
    return truncated ? `${documentUnitTree.numbering || ''} ${documentUnitTree.title}` : null;
  }

  public isSectionOpened(documentUnitTree: DocumentUnitTree): boolean {
    return this.openedEditors.includes(EditorService.getEditorId(documentUnitTree.id));
  }

  public onRightClickNode(documentUnitTree: DocumentUnitTree): void {
    const editorId = EditorService.getEditorId(documentUnitTree.id);
    if (documentUnitTree.id !== this.sectionId && this.openedEditors.includes(editorId)) {
      this.closeSection.emit(editorId);
    }
  }

  public addAfter(event: Event, documentUnitTree: DocumentUnitTree): void {
    event.preventDefault();
    if (!documentUnitTree.documentaryUnit.id) {
      return;
    }
    this.documentaryUnitService.addAfter(documentUnitTree.documentaryUnit.id).subscribe(() => {
      if (!this.isExpanded[documentUnitTree.id] && documentUnitTree.children) {
        this.isExpanded[documentUnitTree.id] = true;
      }
      this.hasDocumentUnitCreated = true;
      this.updateToc();
    });
  }

  public lock(event: Event, documentUnitTree: DocumentUnitTree, locked: boolean): void {
    event.preventDefault();
    if (!documentUnitTree.documentaryUnit.id) {
      return;
    }
    this.documentaryUnitService.lockDocumentUnit(documentUnitTree.documentaryUnit.id, locked).subscribe(() => {
      this.updateToc();
    });
  }

  public activate(event: MatCheckboxChange, documentUnitTree: DocumentUnitTree): void {
    this.hasDocumentUnitActivation = true;
    if (!documentUnitTree.documentaryUnit.id) {
      return;
    }
    this.loaderService.show();
    if (event.checked && documentUnitTree.documentaryUnit?.titles[0]?.level === 1) {
      // there are a level 1 in the current document unit => expand it
      this.isExpanded[documentUnitTree.documentaryUnit.id] = true;
    }
    this.documentaryUnitService
      .activateDocumentUnit(documentUnitTree.documentaryUnit.id, event.checked)
      .pipe(
        finalize(() => {
          this.updateToc();
          pubsub.fire(TOC_UPDATED);
        })
      )
      .subscribe();
  }

  public graphical(event: MatCheckboxChange, documentUnitTree: DocumentUnitTree): void {
    this.hasDocumentUnitGraphicalRemoved = true;
    if (event.checked && documentUnitTree.documentaryUnit?.titles[0]?.level === 1 && documentUnitTree.documentaryUnit.id) {
      // there are a level 1 in the current document unit => expand it
      this.isExpanded[documentUnitTree.documentaryUnit.id] = true;
    }
    documentUnitTree.documentaryUnit.taggedAsGraphicalPage = event.checked;
    if (event.checked && this.udsToRemoveGraphicalPages.has(documentUnitTree.documentaryUnit.id)) {
      this.udsToRemoveGraphicalPages.delete(documentUnitTree.documentaryUnit.id);
    } else if (!event.checked && !this.udsToRemoveGraphicalPages.has(documentUnitTree.documentaryUnit.id)) {
      this.udsToRemoveGraphicalPages.set(documentUnitTree.documentaryUnit.id, true);
    }
  }

  private selectFirstSection(documentaryUnits: DocumentUnitTree[]): void {
    const firstSection = this.findFirstSelectableSection(documentaryUnits);
    if (firstSection) {
      this.selection(firstSection);
    } else {
      this.router.navigate(['accessdenied']);
    }
  }

  private findFirstSelectableSection(documentaryUnits: DocumentUnitTree[]): DocumentUnitTree | null {
    for (const documentaryUnit of documentaryUnits) {
      if (this.checkUDRights(documentaryUnit, RightMode.READ)) {
        return documentaryUnit;
      }

      if (!documentaryUnit.children || documentaryUnit.children.length === 0) {
        continue;
      }

      const found = this.findFirstSelectableSection(documentaryUnit.children);
      if (found) {
        return found;
      }
    }
    return null;
  }

  private findById(nodeTrees: DocumentUnitTree[], id: number, titleId: string): DocumentUnitTree | null {
    let nodeFound: any = null;
    for (const nodeTree of nodeTrees) {
      if (nodeTree.id === id && this.checkUDRights(nodeTree, RightMode.READ)) {
        if (!titleId || nodeTree.titleId === titleId) {
          return nodeTree;
        }
        // Keep the first node of the the titleId not found
        else if (!nodeFound) {
          nodeFound = nodeTree;
        }
      }
      if (nodeTree.children?.length > 0) {
        const found = this.findById(nodeTree.children, id, titleId);
        if (found) {
          this.parentSectionId = nodeTree.parent?.documentaryUnit.id ?? 0;
          this.treeControl.expand(nodeTree); // expand parent(s) of selected node
          return found;
        }
      }
    }
    return nodeFound;
  }

  private findByTitleId(nodeTrees: DocumentUnitTree[], titleId: string): DocumentUnitTree | null {
    for (const nodeTree of nodeTrees) {
      if (nodeTree.titleId === titleId) {
        return nodeTree;
      }
      if (nodeTree.children?.length > 0) {
        const found = this.findByTitleId(nodeTree.children, titleId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  private titleUpdateFromDocument(data: TitleUpdate): void {
    const nodeTree = this.findByTitleId(this.dataSource.data, data.uuid);
    if (nodeTree) {
      nodeTree.numbering = data.titleNumbering;
      nodeTree.title = data.titleContent;
      this.cdr.markForCheck();
    }
  }
}
