<div class="export-plugin-container">
  <ng-container *ngIf="hasRegulator">
    <button
      class="btn btn-light custom-btn"
      (click)="exportRegulatorArchive()"
      [disabled]="!hasTaxonomyImported"
      *jhiHasAnyAuthority="[Authority.EXPORT_ESEF]"
    >
      <ng-container *ngIf="isEsmaRegulator; else secExportBtn">
        <mat-icon class="export-svg" svgIcon="export-esef"></mat-icon>
        <span jhiTranslate="htmlEditor.plugins.documentExport.ESEFExport"></span>
      </ng-container>

      <ng-template #secExportBtn>
        <mat-icon class="export-svg" svgIcon="export-sec"></mat-icon>
        <span jhiTranslate="htmlEditor.plugins.documentExport.SECExport"></span>
      </ng-template>

      <fa-icon *ngIf="isEsmaRegulator" icon="caret-right" transform="grow-15"></fa-icon>
    </button>

    <button
      class="btn btn-light custom-btn"
      (click)="exportDocumentXHTMLWithViewer()"
      [disabled]="!hasTaxonomyImported"
      *jhiHasAnyAuthority="[Authority.EXPORT_XHTML_WITH_VIEWER]"
    >
      <mat-icon class="export-svg" svgIcon="export-ixbrl-viewer"></mat-icon>
      <span jhiTranslate="htmlEditor.plugins.documentExport.xhtmlViewerExport"></span>
      <mat-icon class="export-icon" svgIcon="icon-export-2"></mat-icon>
    </button>
  </ng-container>

  <button class="btn btn-light custom-btn" (click)="exportDocumentCleanedHTML()" *jhiHasAnyAuthority="[Authority.EXPORT_CLEANED_HTML]">
    <mat-icon class="export-svg" svgIcon="export-cleaned-html"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.cleanedHtmlExport"></span>
    <mat-icon class="export-icon" svgIcon="icon-export-2"></mat-icon>
  </button>

  <button
    class="btn btn-light custom-btn"
    (click)="exportDocumentPDF()"
    *jhiHasAnyAuthority="[Authority.EXPORT_PDF_HD, Authority.EXPORT_PDF_LD]"
  >
    <mat-icon class="export-svg" svgIcon="export-pdf"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.pdfExport"></span>
    <fa-icon icon="caret-right" transform="grow-15"></fa-icon>
  </button>

  <button class="btn btn-light custom-btn" (click)="exportDocumentWord()" *jhiHasAnyAuthority="[Authority.EXPORT_WORD]">
    <mat-icon class="export-svg" svgIcon="export-word"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.wordExport"></span>
    <fa-icon icon="caret-right" transform="grow-15"></fa-icon>
  </button>
  <button class="btn btn-light custom-btn" (click)="exportDocumentXHTML()" *jhiHasAnyAuthority="[Authority.EXPORT_XHTML]">
    <mat-icon class="export-svg" svgIcon="export-xhtml"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.xhtmlExport"></span>
    <mat-icon class="export-icon" svgIcon="icon-export-2"></mat-icon>
  </button>

  <button class="btn btn-light custom-btn" (click)="exportDocumentXLIFF()" *jhiHasAnyAuthority="[Authority.EXPORT_XLIFF]">
    <mat-icon class="export-svg" svgIcon="export-xliff"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.xliffExport"></span>
    <fa-icon icon="caret-right" transform="grow-15"></fa-icon>
  </button>
  <button class="btn btn-light custom-btn" (click)="exportDocumentLore()" *jhiHasAnyAuthority="[Authority.EXPORT_LORE]">
    <mat-icon class="export-svg" svgIcon="export-lore"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.loreExport"></span>
    <fa-icon icon="caret-right" transform="grow-15"></fa-icon>
  </button>
  <!-- <button class="btn btn-light custom-btn" (click)="exportDocumentSite()" *jhiHasAnyAuthority="[Authority.EXPORT_SITE_FORMAT]">
    <mat-icon class="export-svg" svgIcon="export-site"></mat-icon>
    <span jhiTranslate="htmlEditor.plugins.documentExport.siteExport"></span>
    <mat-icon class="export-icon" svgIcon="icon-export-2"></mat-icon>
  </button> -->
</div>
