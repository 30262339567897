import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMessage, StompSubscription } from '@stomp/stompjs';
import { AccountService } from 'app/core/auth/account.service';
import { ConfigurationService } from 'app/core/service/configuration.service';
import { ProjectService } from 'app/core/service/project.service';
import { WebSocketNewService } from 'app/core/service/websockets/websocket.service';
import pubsub from 'app/pubsub';
import { WEBSOCKET_CONNECTED } from 'app/pubsub.topics';
import { Authority } from 'app/shared/enum/authority.enum';
import { IProjectProgress, IProjectProgressStatus } from 'app/shared/model/project.model';
import { tap } from 'rxjs/operators';

export interface ProjectProgressInfo {
  entities: string;
  status: string;
  updated: string;
}

// change enum keys order will change the step order in the html page
export enum IExportStep {
  PROJECT_FONT_ENTITY = 'PROJECT_FONT_ENTITY',
  PROJECT_FONT_FAMILY_ENTITY = 'PROJECT_FONT_FAMILY_ENTITY',
  COLORS_ENTITY = 'COLORS_ENTITY',
  STYLES_ENTITY = 'STYLES_ENTITY',
  PAGE_ENTITY = 'PAGE_ENTITY',
  TABLE_MODEL_ENTITY = 'TABLE_MODEL_ENTITY',
  PAGE_BACKGROUND_GROUP_ENTITY = 'PAGE_BACKGROUND_GROUP_ENTITY',
  DATE_FORMAT_ENTITY = 'DATE_FORMAT_ENTITY',
  NUMERIC_FORMAT_ENTITY = 'NUMERIC_FORMAT_ENTITY',
  COLORS_PER_CHAPTER_ENTITY = 'COLORS_PER_CHAPTER_ENTITY',
  CSS_OVERRIDES_ENTITY = 'CSS_OVERRIDES_ENTITY',
  PROJECT_CONFIGURATION_ENTITY = 'PROJECT_CONFIGURATION_ENTITY',
  GRAPH_TEMPLATES_ENTITY = 'GRAPH_TEMPLATES_ENTITY',
  EOLNG_DOCUMENT_ENTITY = 'EOLNG_DOCUMENT_ENTITY',
  AREVIO_ENTITY = 'AREVIO_ENTITY',
  PIMCORE_DYNAMIC_DATA = 'PIMCORE_DYNAMIC_DATA',
  SUMMARY_ENTITY = 'SUMMARY_ENTITY',
  IMAGE_ENTITY = 'IMAGE_ENTITY',
  DOCUMENTARY_UNIT_ENTITY = 'DOCUMENTARY_UNIT_ENTITY',
  TITLE_ENTITY = 'TITLE_ENTITY',
  DOCUMENTARY_UNIT_CONTENT_ENTITY = 'DOCUMENTARY_UNIT_CONTENT_ENTITY',
}
export enum IExportTemplateStep {
  PROJECT_FONT_ENTITY = 'PROJECT_FONT_ENTITY',
  PROJECT_FONT_FAMILY_ENTITY = 'PROJECT_FONT_FAMILY_ENTITY',
  COLORS_ENTITY = 'COLORS_ENTITY',
  STYLES_ENTITY = 'STYLES_ENTITY',
  PAGE_ENTITY = 'PAGE_ENTITY',
  TABLE_MODEL_ENTITY = 'TABLE_MODEL_ENTITY',
  COLORS_PER_CHAPTER_ENTITY = 'COLORS_PER_CHAPTER_ENTITY',
  CSS_OVERRIDES_ENTITY = 'CSS_OVERRIDES_ENTITY',
  GRAPH_TEMPLATES_ENTITY = 'GRAPH_TEMPLATES_ENTITY',
}
@Component({
  selector: 'jhi-import-project-detail',
  templateUrl: './import-project-detail.component.html',
  styleUrls: ['./import-project-detail.component.scss'],
})
export class ImportProjectDetailComponent implements OnInit {
  public progressStatus = {};
  public progressStepStatus: string[];
  public ProjectProgressStatusEnum = IProjectProgressStatus;
  public projectId: number;
  public cockpitURL: string;
  public projectStatus = IProjectProgressStatus.IN_PROGRESS;

  // WebSocket
  public subscriptions: StompSubscription[] = [];
  private topic = '/topic/';
  public readonly Authority = Authority;
  public isOnlyTemplate: boolean;
  constructor(
    private route: ActivatedRoute,
    public accountService: AccountService,
    private configurationService: ConfigurationService,
    private projectService: ProjectService,
    private websocketNewService: WebSocketNewService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.projectId;
    this.isOnlyTemplate = this.route.snapshot.params.isOnlyTemplate;

    this.progressStepStatus = [];

    this.route.queryParams
      .pipe(
        tap({
          next: params => {
            this.isOnlyTemplate = params.isOnlyTemplate === 'true';
            const steps = this.isOnlyTemplate ? Object.values(IExportTemplateStep) : Object.values(IExportStep);
            this.updateProgress(steps);
          },
          error: () => {
            this.isOnlyTemplate = false;
            const steps = Object.values(IExportStep);
            this.updateProgress(steps);
          },
        })
      )
      .subscribe();

    this.configurationService.getConfiguration().subscribe(data => {
      this.cockpitURL = data.sppUri;
    });

    this.getProjectProgress();

    // WebSocket
    const topic_suffix = 'project-progress';
    if (this.websocketNewService.isConnected()) {
      const WSConnection = this.websocketNewService.getConnection();
      if (WSConnection) {
        this.subscriptions.push(
          WSConnection.subscribe(this.topic + this.websocketNewService.getPrefix() + topic_suffix, this.subscribeWebsocketTopic.bind(this))
        );
      }
    } else {
      pubsub.on(WEBSOCKET_CONNECTED, () => {
        const WSConnection = this.websocketNewService.getConnection();
        if (WSConnection) {
          this.subscriptions.push(
            WSConnection.subscribe(
              this.topic + this.websocketNewService.getPrefix() + topic_suffix,
              this.subscribeWebsocketTopic.bind(this)
            )
          );
        }
      });
    }
  }

  private updateProgress(steps: string[]): void {
    steps.forEach(step => {
      this.progressStepStatus.push(step);
      this.progressStatus[step] = IProjectProgressStatus.IN_PROGRESS;
    });
  }

  public navigate(path: string): void {
    this.router.navigate([`/document/${this.accountService.documentId}/${path}`]);
  }

  public refresh(): void {
    this.getProjectProgress();
  }

  subscribeWebsocketTopic(data: IMessage): void {
    if (data?.body) {
      this.getProjectProgress();
    }
  }

  private getProjectProgress() {
    this.projectService.getImportProgress(this.projectId).subscribe((projectProgress: IProjectProgress[]) => {
      if (!projectProgress || projectProgress.find(entry => entry.status === IProjectProgressStatus.ERROR)) {
        this.projectStatus = IProjectProgressStatus.ERROR;
        return;
      }

      this.projectStatus = projectProgress.find(entry => entry.status === IProjectProgressStatus.DONE)
        ? IProjectProgressStatus.DONE
        : IProjectProgressStatus.IN_PROGRESS;

      if (this.projectStatus === IProjectProgressStatus.DONE && !isNaN(this.accountService.documentId)) {
        // navigate to eol page only if we have the doc ID
        this.navigate('html-editor');
      }

      projectProgress.forEach((progress: IProjectProgress) => {
        if (progress.entities) {
          this.progressStatus[progress.entities] = progress.stepResult;
        }
      });
    });
  }

  ngOnDestroy(): void {
    pubsub.off(WEBSOCKET_CONNECTED);

    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
    this.subscriptions = [];
  }

  checkIsNaN(value: any) {
    return isNaN(value);
  }
}
