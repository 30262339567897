<div class="document_name_reminder_container_editor">
  <div class="header">
    <h1>{{ documentNameReminder?.name }}</h1>
    <div #documentNameReminderEditorToolbarElement class="editor-reminder-toolbar"></div>
  </div>

  <div class="edition_view custom-styles-container">
    <div class="document_name_reminder_body eolng_document_name_reminder_body">
      <div #documentNameReminderEditorElement class="document_name_reminder_element eolng_document_name_reminder_element"></div>
    </div>

    <div class="actions-btn">
      <button type="button" mat-raised-button color="accent" (click)="cancel()">
        {{ 'htmlEditor.plugins.documentNameReminder.cancel' | translate }}
      </button>
      <button type="button" mat-stroked-button color="primary" (click)="reset()">
        {{ 'htmlEditor.plugins.documentNameReminder.reset' | translate }}
      </button>
      <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="!hasEditorDataChanged">
        {{ 'htmlEditor.plugins.documentNameReminder.validate' | translate }}
      </button>
    </div>
  </div>
</div>
